"use client";
import { WagmiProvider, configureChains, createConfig, http } from "wagmi";
import { mainnet, bscTestnet, bsc } from "wagmi/chains";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";

import { config } from "../config";


const queryClient = new QueryClient();

export const Web3Provider = ({ children }) => {
  return (
    // <WagmiProvider config={config}>
    //   <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>{children}</ConnectKitProvider>
    //   </QueryClientProvider>
    // </WagmiProvider>
  );
};
