export function Status1({
    isConnecting,
    isDisconnected,
    isConnected,
    address,
  }) {
    return (
      <div>
        {isConnecting ? (
          <div style={{ color: 'yellow'}}>Connecting...</div>
        ) : isDisconnected ? (
          <div style={{ color: 'orange'}}>Disconnected</div>
        ) : isConnected ? (
          <div style={{ color: 'green'}}>connected</div>
        ) : (
          <div style={{ color: 'green'}}>{address}</div>
        )}
      </div>
    )
  }