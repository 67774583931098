import { React, useState, useEffect, useRef } from "react";
import { formatEther, parseEther } from "viem";
import { useWriteContract, useWaitForTransactionReceipt, useBalance, useAccount, useReadContract, useWatchContractEvent, useWatchBlocks } from "wagmi";

export function PreSale({
  abiNeoContract,
  addressNeoContract,
  abiPreSaleContract,
  addressPreSaleContract,
  abiUsdtContract,
  addressUsdtContract,
  tokenPerWei,
  tokenPerUSDTcent,
  userBalanceEth,
  userBalanceNeo,
  userBalanceUsdt,
}) {
  const walletAddress = useAccount();

  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  // USE STATES
  const [updated, setUpdated] = useState(0);
  const [previewBuyWithETH, setPreviewBuyWithETH] = useState(0);
  const [previewBuyWithUSDT, setPreviewBuyWithUSDT] = useState(0);
  const [maxEthValue, setMaxEthValue] = useState(0);
  const [maxUsdtValue, setMaxUsdtValue] = useState(0);
  const [btnClicked, setBtnClicked] = useState("");
  const [neoPreSaleBalance, setNeoPreSaleBalance] = useState(0);

  const [preSaleStartTimestamp, setPreSaleStartTimestamp] = useState(0);
  const [preSaleEndTimestamp, setPreSaleEndTimestamp] = useState(0);

  const [preSaleStarted, setPreSaleStarted] = useState(false);
  const [preSaleEnded, setPreSaleEnded] = useState(false);
  const [preSaleSoldOut, setPreSaleSoldOut] = useState(false);
  const [neoPreSaleEnd, setNeoPreSaleEnd] = useState("00:00:00");
  const [neoPreSaleStart, setNeoPreSaleStart] = useState("00:00:00");
  const [isTxHashForBuyWithETH, setIsTxHashForBuyWithETH] = useState(true);
  const [timestampLoading, setTimestampLoading] = useState(true);
  const [approvalRequired, setApprovalRequired] = useState(true);
  const [approvalAmountBigInt, setApprovalAmountBigInt] = useState(BigInt(0));
  const [visualApprovalAmount, setVisualApprovalAmount] = useState(0);
  // setApprovalRequired(false);
  // USE REFS
  const _timestampLoading = useRef(true);
  const blockNumber = useRef();
  const _pollDataInterval = useRef(undefined);
  const _initializing = useRef(true);
  
  const triggerApprovalUpdate = useRef(false);
  const triggerContributeUsdtUpdate = useRef(false);
  const triggerContributeEthUpdate = useRef(false);
  
  const _preSaleLoadRequired = useRef(true);
  const _neoPreSaleEndTimestamp = useRef(0);
  const _neoPreSaleStartTimestamp = useRef(0);

  const blockTimestamp = useRef(BigInt(0));
  const _approvalAmountBigInt = useRef(BigInt(0));
  const _neoForSaleBalance = useRef("0");
  const _neoForSaleBalanceBigInt = useRef(BigInt(0));
  const loading = useRef(true);

  useEffect(() => {
    // _updatePreSaleData();
    //console.log("walletAddress", walletAddress.address);
    //Runs on the first render
    //And any time any dependency value changes
    
    if (_neoForSaleBalance.current !== "0") {
      console.log("Got Here 1");
      setNeoPreSaleBalance(_neoForSaleBalance.current);
    }
    if (_approvalAmountBigInt.current > BigInt(0)) {
      console.log("Got Here 2");
      setApprovalAmountBigInt(_approvalAmountBigInt.current);
      setVisualApprovalAmount(formatEther(_approvalAmountBigInt.current.toString()));
    }
    
    // if (morphBalance.current !== "0") {
    //   setUserAmountMorph(morphBalance.current);
    // }
  }, [updated]);

  useWatchBlocks({
    onBlock(block) {
      // console.log('New block Number', block.number)
      // console.log('New block Timestamp', block.timestamp)
      blockNumber.current = block.number;
      blockTimestamp.current = block.timestamp;
      setUpdated(updated + 1);
    },
  });
  // allowance(address _owner, address spender)
  const _getApprovalAmount = useReadContract({
    abi: abiUsdtContract,
    address: addressUsdtContract,
    functionName: "allowance",
    args: [walletAddress.address, addressPreSaleContract],
    blockNumber: blockNumber.current,
  });
  const _preSaleStartTimestamp = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "preSaleStartTimestamp",
    blockNumber: blockNumber.current,
  });
  const _preSaleEndTimestamp = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "preSaleEndTimestamp",
    blockNumber: blockNumber.current,
  });
  // const _isPreSaleActive = useReadContract({
  //   abi: abiPreSaleContract,
  //   address: addressPreSaleContract,
  //   functionName: "isPreSaleActive",
  //   blockNumber: blockNumber.current,
  // });

  const _neoForSale = useBalance({
    address: addressPreSaleContract,
    token: addressNeoContract,
    blockNumber: blockNumber.current,
  });

  function _test() {
    _updatePreSaleData();
    setUpdated(updated + 1);
  }
  function _startPollingData() {
    console.log("setting up Polling interval");
    // _pollDataInterval.current = setInterval(() => _updatePreSaleData(), 1000);
    _pollDataInterval.current = setInterval(() => _test(), 1000);
    // We run it once immediately so we don't have to wait for it
    // _updatePreSaleData();
  }

  function _stopPollingData() {
    clearInterval(_pollDataInterval.current);
    _pollDataInterval.current = undefined;
  }

  // const afterTransaction = useRef(false);

  function _secondsToHMS(secs) {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var sign = secs < 0 ? "-" : "";
    secs = Math.abs(secs);
    return sign + z((secs / 3600) | 0) + ":" + z(((secs % 3600) / 60) | 0) + ":" + z(secs % 60);
  }

  function _updatePreSaleData() {
    let timeDiffInSeconds;
    // console.log("blockTimestamp.current", blockTimestamp.current);
    // console.log("startTimestamp", _neoPreSaleStartTimestamp.current);
    // console.log("endTimestamp", _neoPreSaleEndTimestamp.current);
    if (_timestampLoading.current && blockTimestamp.current > BigInt(0)) {
      console.log("HERE 0", _timestampLoading.current);
      _timestampLoading.current = false;
      console.log("HERE 0.0", _timestampLoading.current);
    }
    if (blockTimestamp.current >= _neoPreSaleStartTimestamp.current && blockTimestamp.current <= _neoPreSaleEndTimestamp.current) {
      console.log("HERE 1");
      setPreSaleStarted(true);
      setPreSaleEnded(false);
    }
    
    if (blockTimestamp.current >= _neoPreSaleEndTimestamp.current) {
      
      timeDiffInSeconds = 0;
      setPreSaleEnded(true);
    } else {
      timeDiffInSeconds = _neoPreSaleEndTimestamp.current - blockTimestamp.current;
    }
    if (blockTimestamp.current < _neoPreSaleStartTimestamp.current) {
      console.log("HERE 3");
      setNeoPreSaleStart(_secondsToHMS(Number(_neoPreSaleStartTimestamp.current - blockTimestamp.current)));
      setPreSaleStarted(false);
      setPreSaleEnded(false);
      console.log("HERE 4");
    } else {
      console.log("HERE 5");
      setNeoPreSaleStart(_secondsToHMS(0));
      setPreSaleStarted(true);
      console.log("HERE 6");
      
    }
    setNeoPreSaleEnd(_secondsToHMS(Number(timeDiffInSeconds)));
    console.log("HERE 7");
    
  }

  if (_preSaleLoadRequired.current && _preSaleStartTimestamp.isSuccess && _preSaleEndTimestamp.isSuccess) {
    _preSaleLoadRequired.current = false;
    // console.log("Pre Sale Data loaded");
    // console.log("_preSaleTokenPerWei.data",_preSaleTokenPerWei.data);
    // console.log("_preSaleTokenPerUSDTcent.data",_preSaleTokenPerUSDTcent.data);
    // console.log("_preSaleStartTimestamp.data",_preSaleStartTimestamp.data);
    // console.log("_preSaleEndTimestamp.data",_preSaleEndTimestamp.data);
    // console.log("_preSaleIsActive.data",_preSaleIsActive.data);
    _neoPreSaleEndTimestamp.current = _preSaleEndTimestamp.data;
    _neoPreSaleStartTimestamp.current = _preSaleStartTimestamp.data;
  }

  if (_initializing.current === true) {
    _initializing.current = false;
    _startPollingData();
  }

  if (isConfirmed && triggerApprovalUpdate.current === true && _getApprovalAmount.isSuccess) {
    triggerApprovalUpdate.current = false;
    _approvalAmountBigInt.current = _getApprovalAmount.data;
    setUpdated(updated + 1);
  }

  if (_neoForSale.isSuccess && (loading.current || (isConfirmed && (triggerContributeEthUpdate.current === true || triggerContributeUsdtUpdate.current === true)))) {
    triggerContributeEthUpdate.current = false;
    triggerContributeUsdtUpdate.current = false;
    loading.current = false;
    _neoForSaleBalance.current = formatEther(_neoForSale.data.value.toString());
    _neoForSaleBalanceBigInt.current = _neoForSale.data.value;
    setUpdated(updated + 1);
  }
  
  if(_timestampLoading.current === false && timestampLoading === true) {
    console.log("OK", timestampLoading);
    setTimestampLoading(false);
    console.log("OK", timestampLoading);
  }

  const inputEvent1 = async (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      /* global BigInt */
      var amountWeiBigInt = BigInt(Number(parseFloat(e.target.value).toFixed(6) * 1000000)) * BigInt(10 ** 12);
      let amountTokenBigInt = BigInt(tokenPerWei) * amountWeiBigInt;

      // const coin1PreSaleBalanceBigInt = BigInt(_coin1PreSaleBalance.current);

      // // adjusting the amountWeiBigInt and amountTokenBigInt
      // if (amountTokenBigInt > coin1PreSaleBalanceBigInt) {
      //   amountTokenBigInt = coin1PreSaleBalanceBigInt;
      //   amountWeiBigInt = coin1PreSaleBalanceBigInt / BigInt(tokenPerWei);
      // }
      setMaxEthValue();
      setPreviewBuyWithETH(formatEther(amountTokenBigInt.toString()));
    }
  };

  const inputEvent2 = (e) => {
    // console.log("tokenPerUSDTcent", tokenPerUSDTcent);
    // console.log("e.target.value", e.target.value);
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      var amountUSDTCentBigInt = BigInt(Math.floor(Number(e.target.value))) * BigInt(10 ** 18);
      let amountNeoBigInt = BigInt(tokenPerUSDTcent) * amountUSDTCentBigInt;

      // if (approvalAmountBigInt >= NOT AMOUNT! amountUSDTCentBigInt) {
      //   setApprovalRequired(false);
      // } else {
      //   setApprovalRequired(true);
      // }
      // TODO CHECK APPROVAL FOR amountUSDTCentBigInt

      // CHECK USER BALANCE and amount of USDT to use for buying NEO
      const maxUserBalanceUsdtCapBigInt = parseEther(userBalanceUsdt);
      if (amountUSDTCentBigInt > maxUserBalanceUsdtCapBigInt) {
        amountUSDTCentBigInt = maxUserBalanceUsdtCapBigInt;
        amountNeoBigInt = BigInt(tokenPerUSDTcent) * amountUSDTCentBigInt;
      }

      // CHECK IF USER BALANCE and amount of USDT
      if (amountNeoBigInt > _neoForSaleBalanceBigInt.current) {
        amountNeoBigInt = _neoForSaleBalanceBigInt.current;
        amountUSDTCentBigInt = amountNeoBigInt / BigInt(tokenPerUSDTcent);
      }
      console.log("amountNeoBigInt", amountNeoBigInt);
      console.log("amountUSDTCentBigInt", amountUSDTCentBigInt);
      // console.log("test", formatEther(amountTokenBigInt.toString()));
      setMaxUsdtValue(formatEther(amountUSDTCentBigInt.toString()));
      setPreviewBuyWithUSDT(formatEther(amountNeoBigInt.toString()));
    } else {
      setMaxUsdtValue(0);
      setPreviewBuyWithUSDT(0);
    }
  };
  return (
    <div role="alert">
      <hr></hr>
      <h4>NEO Pre-Sale</h4>
      {!timestampLoading && preSaleStarted && !preSaleEnded && !preSaleSoldOut && <h5>---&gt; ends in {neoPreSaleEnd} &lt;---</h5>}
      {!timestampLoading && preSaleStarted && preSaleEnded && !preSaleSoldOut && <h5>---&gt; is already over &lt;---</h5>}
      {!timestampLoading && !preSaleStarted && !preSaleEnded && !preSaleSoldOut && <h5>---&gt; starts in {neoPreSaleStart} &lt;---</h5>}
      {!timestampLoading && preSaleSoldOut && <h5>---&gt; PRE-SALE SOLD OUT ! &lt;---</h5>}
      {timestampLoading && <h5>---&gt; Loading Contract Data ... &lt;---</h5>}
      <hr></hr>
      {/* <h4>{tokensSold} NEO already sold !</h4>
      <p>
        (for {usdtContributed} USDT and {ethContributed} ETH)
      </p> */}
      <hr></hr>
      <form
        onSubmit={async (event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();

          const formData = new FormData(event.target);
          if (btnClicked === "ContributeETH") {
            const amountETH = formData.get("amountETH");

            if (Number(amountETH) > 0) {
              var amountWeiBigInt = BigInt(Number(parseFloat(amountETH).toFixed(6) * 1000000)) * BigInt(10 ** 12);
              const balanceETHBigInt = BigInt(Number.parseFloat(userBalanceEth).toFixed(3) * 1000) * BigInt(10 ** 15);

              if (amountWeiBigInt <= balanceETHBigInt) {
                writeContract({
                  address: addressPreSaleContract,
                  abi: abiPreSaleContract,
                  functionName: "contribute",
                  args: [],
                  value: amountWeiBigInt.toString(),
                });
                triggerContributeEthUpdate.current = true;
                // afterTransaction.current = true;
                setIsTxHashForBuyWithETH(true);
                // contributeETH(amountWeiBigInt);
              } else {
                alert(`Amount ETH ${amountETH} exceeds your ETH Balance ${userBalanceEth}`);
              }
            } else alert("Missing amount of ETH to contribute");
          }
          if (btnClicked === "ContributeUSDT" || btnClicked === "Approve") {
            //const amountUSDT = formData.get("amountUSDT");
            const amountUSDT = Number(maxUsdtValue).toFixed(0);
            // console.log("amountUSDT", amountUSDT)
            if (Number(amountUSDT) > 0) {
              var amountUSDTBigInt = BigInt(Math.floor(Number(amountUSDT))) * BigInt(10 ** 18);
              const balanceUSDTBigInt = BigInt(Math.floor(parseFloat(Number(userBalanceUsdt).toFixed(2)))) * BigInt(10 ** 18);

              const _value = BigInt(parseEther(amountUSDT).toString());

              if (amountUSDTBigInt <= balanceUSDTBigInt) {
                if (btnClicked === "Approve") {
                  console.log("btnClicked = Approve");
                  writeContract({
                    address: addressUsdtContract,
                    abi: abiUsdtContract,
                    functionName: "approve",
                    args: [addressPreSaleContract, _value],
                  });
                  triggerApprovalUpdate.current = true;
                }
                if (btnClicked === "ContributeUSDT") {
                  writeContract({
                    address: addressPreSaleContract,
                    abi: abiPreSaleContract,
                    functionName: "contributeUSDT",
                    args: [_value],
                  });
                  triggerContributeUsdtUpdate.current = true;
                }
                // afterTransaction.current = true;
                setIsTxHashForBuyWithETH(false);
                // setUpdated(updated + 1);
                // contributeUSDT(amountUSDTBigInt);
              } else {
                alert(`Amount USDT ${amountUSDT} exceeds your ETH Balance ${userBalanceUsdt}`);
              }
            } else alert("Missing amount of USDT to contribute");
          }

          setBtnClicked("");
        }}
      >
        <p>{neoPreSaleBalance} NEO for Sale</p>
        <h5>Your Balance:</h5>
        <p>{userBalanceEth} ETH</p>
        <p>{userBalanceNeo} NEO</p>
        <p>{userBalanceUsdt} USDT</p>
        <hr></hr>
        <div className="form-group col-8">
          <h5>Buy NEO with ETH:</h5>
          <input className="form-control" type="number" step="0.001" name="amountETH" placeholder="0.001" onChange={inputEvent1} />
          <p>You will receive {previewBuyWithETH} NEO</p>
          {maxEthValue > 0 && typeof maxEthValue !== "undefined" ? <p>(using: {maxEthValue} ETH)</p> : ""}
          <hr></hr>
          <button
            className="btn btn-light mx-2"
            onClick={() => setBtnClicked("ContributeETH")}
            disabled={!isPending && preSaleStarted && !preSaleEnded && !preSaleSoldOut ? false : true}
            type="submit"
          >
            {isPending ? "Confirming..." : "Contribute ETH"}
          </button>

          {isTxHashForBuyWithETH && hash && <div>Transaction Hash: {hash}</div>}
          {isTxHashForBuyWithETH && isConfirming && <div>Waiting for confirmation...</div>}
          {isTxHashForBuyWithETH && isConfirmed && <div>Transaction confirmed.</div>}
        </div>

        <hr></hr>

        <div className="form-group col-8">
          <h5>Buy NEO with USDT:</h5>
          <input className="form-control" type="number" step="1" name="amountUSDT" placeholder="1" onChange={inputEvent2} />
          <p>You will receive {previewBuyWithUSDT} NEO</p>
          {maxUsdtValue > 0 && typeof maxUsdtValue !== "undefined" ? <p>(using: {maxUsdtValue} USDT)</p> : ""}
          <hr></hr>
          <div>
            <button
              className="btn btn-light mx-2 my-2"
              onClick={() => setBtnClicked("Approve")}
              // disabled={isPending || !preSaleStarted || preSaleEnded || preSaleSoldOut || !approvalRequired ? true : false}
              disabled={isPending || !preSaleStarted || preSaleEnded || preSaleSoldOut ? true : false}
              type="submit"
            >
              {isPending ? "Confirming..." : "Approve"}
            </button>
            <p>Amount approved: {visualApprovalAmount} USDT</p>
          </div>
          <button
            className="btn btn-light mx-2"
            onClick={() => setBtnClicked("ContributeUSDT")}
            // disabled={isPending || !preSaleStarted || preSaleEnded || preSaleSoldOut || approvalRequired ? true : false}
            disabled={isPending || !preSaleStarted || preSaleEnded || preSaleSoldOut ? true : false}
            type="submit"
          >
            {isPending ? "Confirming..." : "Contribute USDT"}
          </button>

          {!isTxHashForBuyWithETH && hash && <div>Transaction Hash: {hash}</div>}
          {!isTxHashForBuyWithETH && isConfirming && <div>Waiting for confirmation...</div>}
          {!isTxHashForBuyWithETH && isConfirmed && <div>Transaction confirmed.</div>}
        </div>
      </form>
    </div>
  );
}
