import { React, useState, useRef, useEffect } from "react";
import { formatEther, parseEther } from "viem";
import { useAccount, useReadContract, useWatchContractEvent, useWatchBlockNumber, useBalance } from "wagmi";

export function MainOverview({
  abiNeoContract,
  addressNeoContract,
  abiMorphContract,
  addressMorphContract,
  abiStakingContract,
  addressStakingContract,
  abiPreSaleContract,
  addressPreSaleContract,
  addressUsdtContract,
  updateAddressUniswapRouter02,
  providePreSaleData,
  provideBalanceData,
}) {
  const walletAddress = useAccount();
  /* global BigInt */

  // USE STATES
  const [updated, setUpdated] = useState(0);
  const [neosBurned, setNeosBurned] = useState(0);
  const [neosBurnedPercentage, setNeosBurnedPercentage] = useState(0);

  const [userStakedShares, setUserStakedShares] = useState(0);
  const [userStakedSharesPercentage, setUserStakedSharesPercentage] = useState(0);
  const [totalStakedShares, setTotalStakedShares] = useState(0);
  const [userPreviewMorph, setUserPreviewMorph] = useState(0);


  const blockNumber = useRef(0);
  const _neosBurned = useRef("0");
  const _neosBurnedPrev = useRef("0");

  // const _neostotalSupply = useRef("500000000000000000000000000");

  const _totalSharesStaked = useRef("0");
  const _totalSharesStakedPrev = useRef("0");

  const _totalMorphStaked = useRef("0");
  const _userShares = useRef("0");
  const _userSharesPrev = useRef("0");

  const _userPreviewMorph = useRef("0");
  const _userPreviewMorphPrev = useRef("0");
  // const userMorphYield = useRef(0);

  const updateRequired = useRef(true);
  const _uniswapRouter02Loaded = useRef(false);

  const _preSaleLoadRequired = useRef(true);
  const _balanceUpdateRequired = useRef(true);

  useEffect(() => {
    //console.log("entering useEffect ...");
    //Runs on the first render
    //And any time any dependency value changes

    if (_totalSharesStaked.current !== "0" && _totalSharesStakedPrev.current !== _totalSharesStaked.current) {
      _totalSharesStakedPrev.current = _totalSharesStaked.current;
      setTotalStakedShares(Number.parseFloat(_totalSharesStaked.current).toFixed(2));
    }
    if (_userShares.current !== "0" && _userSharesPrev.current !== _userShares.current) {
      _userSharesPrev.current = _userShares.current;
      setUserStakedShares(Number.parseFloat(_userShares.current).toFixed(2));
      let _stakedPercentage = ((Number.parseFloat(_userShares.current) * 100) / _totalSharesStaked.current).toFixed(2);

      setUserStakedSharesPercentage(_stakedPercentage.toString());
    }
    if (_neosBurned.current !== "0" && _neosBurnedPrev.current !== _neosBurned.current) {
      _neosBurnedPrev.current = _neosBurned.current;
      // setUserAmountMorph(formatEther(userMorphBalance.current.toString()))
      setNeosBurned(_neosBurned.current);

      let _neosBurnedPercentage = ((Number.parseFloat(_neosBurned.current) * 100) / 500000000).toFixed(2);
      setNeosBurnedPercentage(_neosBurnedPercentage.toString());
    }
    if (_userPreviewMorph.current !== "0" && _userPreviewMorphPrev.current !== _userPreviewMorph.current) {
      _userPreviewMorphPrev.current = _userPreviewMorph.current;
      // console.log("_userPreviewMorph.current",_userPreviewMorph.current)
      setUserPreviewMorph(Number.parseFloat(_userPreviewMorph.current).toFixed(2));
    }
    if (_userPreviewMorph.current === "0" && _totalSharesStaked.current !== "0") {      
      updateRequired.current = true;
    }

    // if (stakedSharesTotal.current !== 0) {
    //   // setUserAmountNeo(formatEther(userNeoBalance.current.toString()))
    // }
  }, [updated]);

  // Trigger update when Neo is transferred
  useWatchContractEvent({
    abi: abiNeoContract,
    address: addressNeoContract,
    eventName: "Transfer",
    onLogs(logs) {
      console.log("Neo Transfer logged!", logs);
      updateRequired.current = true;
      _balanceUpdateRequired.current = true;
      setUpdated(updated + 1);
    },
  });
  useWatchContractEvent({
    abi: abiMorphContract,
    address: addressMorphContract,
    eventName: "Transfer",
    onLogs(logs) {
      console.log("Morph Transfer logged!", logs);
      updateRequired.current = true;
      _balanceUpdateRequired.current = true;
      setUpdated(updated + 1);
    },
  });

  useWatchContractEvent({
    abi: abiStakingContract,
    address: addressStakingContract,
    eventName: "Deposit",
    onLogs(logs) {
      console.log("Staking Transfer (deposit, redeem, withdraw or  logged!", logs);
      updateRequired.current = true;
      _balanceUpdateRequired.current = true;
      setUpdated(updated + 1);
    },
  });

  // Trigger update when depositing, redeeming, withdraw and deposit
  // useWatchContractEvent({
  //   abi: abiStakingContract,
  //   address: addressStakingContract,
  //   eventName: "Transfer",
  //   onLogs(logs) {
  //     console.log("Staking Tansfer logged!", logs);
  //     updateRequired.current = true;
  //     setUpdated(updated + 1);
  //   },
  // });
  useWatchBlockNumber({
    onBlockNumber(blockNumber1) {
      // if (updateRequired.current) {
      blockNumber.current = blockNumber1;
      setUpdated(updated + 1);
      // console.log("blockNumber.current", blockNumber.current);
      //}
    },
  });

  // PRE-SALE related
  const _preSaleTokenPerWei = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "tokenPerWei",
    blockNumber: blockNumber.current,
  });
  const _preSaleTokenPerUSDTcent = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "tokenPerUSDTcent",
    blockNumber: blockNumber.current,
  });
  const _preSaleStartTimestamp = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "preSaleStartTimestamp",
    blockNumber: blockNumber.current,
  });
  const _preSaleEndTimestamp = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "preSaleEndTimestamp",
    blockNumber: blockNumber.current,
  });
  const _preSaleIsActive = useReadContract({
    abi: abiPreSaleContract,
    address: addressPreSaleContract,
    functionName: "isPreSaleActive",
    blockNumber: blockNumber.current,
  });

  // Get uniswapRouter02 for PancakeSwap
  const _uniswapRouter02 = useReadContract({
    abi: abiNeoContract,
    address: addressNeoContract,
    functionName: "uniswapRouter",
    args: [],
    blockNumber: blockNumber.current,
  });
  // get total Shares staked
  const _stakingTotalShares = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "totalSupply",
    args: [],
    blockNumber: blockNumber.current,
  });
  // get total assets (Morph)
  const _stakingTotalMorph = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "totalAssets",
    args: [],
    blockNumber: blockNumber.current,
  });

  // get amount of user shares
  const _stakingUserShares = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "balanceOf",
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  });

   
  const _stakingPreviewUserMorph = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "previewRedeem",
    args: [BigInt(parseEther(_userShares.current))],
    blockNumber: blockNumber.current,
  });


  const _neosBurnedResult = useBalance({
    address: "0x000000000000000000000000000000000000dead",
    token: addressNeoContract,
    blockNumber: blockNumber.current,
  });
  const _neosBurned1Result = useBalance({
    address: "0x0000000000000000000000000000000000000001",
    token: addressNeoContract,
    blockNumber: blockNumber.current,
  });

  const _useBalanceETH = useBalance({
    address: walletAddress.address,
    blockNumber: blockNumber.current,
  });
  const _useBalanceNEO = useBalance({
    address: walletAddress.address,
    token: addressNeoContract,
    blockNumber: blockNumber.current,
  });
  const _useBalanceMORPH = useBalance({
    address: walletAddress.address,
    token: addressMorphContract,
    blockNumber: blockNumber.current,
  });
  const _useBalanceUSDT = useBalance({
    address: walletAddress.address,
    token: addressUsdtContract,
    blockNumber: blockNumber.current,
  });

  if (_balanceUpdateRequired.current && _useBalanceETH.isSuccess && _useBalanceNEO.isSuccess && _useBalanceMORPH.isSuccess && _useBalanceUSDT.isSuccess) {
    // _userBalanceETH.current = formatEther(_useBalanceETH.data.toString());
    // _userBalanceNEO.current = formatEther(_useBalanceNEO.data.toString());
    // _userBalanceMORPH.current = formatEther(_useBalanceMORPH.data.toString());
    // _userBalanceUSDT.current = formatEther(_useBalanceUSDT.data.toString());
    provideBalanceData(
      formatEther(_useBalanceETH.data.value.toString()),
      formatEther(_useBalanceNEO.data.value.toString()),
      formatEther(_useBalanceMORPH.data.value.toString()),
      formatEther(_useBalanceUSDT.data.value.toString())
    );
    _balanceUpdateRequired.current = false;
  }

  // just load once at the beginning !
  if (
    _preSaleLoadRequired.current &&
    _preSaleTokenPerWei.isSuccess &&
    _preSaleTokenPerUSDTcent.isSuccess
  ) {
    _preSaleLoadRequired.current = false;
    // console.log("Pre Sale Data loaded");
    // console.log("_preSaleTokenPerWei.data",_preSaleTokenPerWei.data);
    // console.log("_preSaleTokenPerUSDTcent.data",_preSaleTokenPerUSDTcent.data);
    // console.log("_preSaleStartTimestamp.data",_preSaleStartTimestamp.data);
    // console.log("_preSaleEndTimestamp.data",_preSaleEndTimestamp.data);
    // console.log("_preSaleIsActive.data",_preSaleIsActive.data);

    providePreSaleData(_preSaleTokenPerWei.data, _preSaleTokenPerUSDTcent.data);

  }

  // just load once at the beginning !
  if (!_uniswapRouter02Loaded.current && _uniswapRouter02.isSuccess) {
    console.log("Main Overview uniswapRouter02address", _uniswapRouter02.data);
    updateAddressUniswapRouter02(_uniswapRouter02.data);
    _uniswapRouter02Loaded.current = true;
  }

  if (
    updateRequired.current &&
    _neosBurned1Result.isSuccess &&
    _neosBurnedResult.isSuccess &&
    _stakingUserShares.isSuccess &&
    _stakingPreviewUserMorph.isSuccess &&
    _stakingTotalShares.isSuccess &&
    _stakingTotalMorph.isSuccess    
  ) {

    // console.log("Update required ...");
    updateRequired.current = false;
    _neosBurned.current = formatEther((_neosBurnedResult.data.value + _neosBurned1Result.data.value).toString());
    //_neosBurned.current = formatEther((_neosBurnedResult.data.value).toString());

    _userShares.current = formatEther(_stakingUserShares.data.toString());
    _totalSharesStaked.current = formatEther(_stakingTotalShares.data.toString());
    _totalMorphStaked.current = formatEther(_stakingTotalMorph.data.toString());

    _userPreviewMorph.current = formatEther(_stakingPreviewUserMorph.data.toString());

    // console.log("_userPreviewMorph", typeof _userPreviewMorph.current);
    // console.log("_userPreviewMorph", _userPreviewMorph.current);
    // console.log("_totalSharesStaked", _totalSharesStaked.current);

    // if (_userPreviewMorph.current === "0" && _totalSharesStaked.current !== "0") {
    //   // console.log("OUT OF SYNC");
    //   updateRequired.current = true;
    // }
    //   console.log("_morphResult", _morphResult);
    //   console.log("_stakingResult", _stakingResult);

    //   console.log("_morphResult",_morphResult);
    //   userNeoBalance.current = _neoResult.data;
    //   sta.current = _neoResult.data;
    //   userMorphBalance.current = _morphResult.data;
    // setUpdated(updated + 1);
  }


  return (
    <div>
      <hr></hr>

      <h4>{neosBurned} NEOs burned </h4>
      <p>({neosBurnedPercentage}% of total Supply)</p>
      <hr></hr>
      <h4>Your Staked Shares: {userStakedShares}</h4>
      <p>
        ({userStakedSharesPercentage}% of total {totalStakedShares} amount staked)
      </p>
      <hr></hr>
      <h4>Shares equivalent to: {userPreviewMorph} Morph</h4>
      {/* <p> ({userMorphYield}% yield)</p>  */}
      <hr></hr>
    </div>
  );
}
