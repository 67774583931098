import React from "react";

export function NoWalletDetected({ addressPreSaleContract, addressNeoContract }) {
  return (
    <div className="NoWalletDetected">
      <hr></hr>
      <h2>Participate directly in Pre Sale </h2>
      <p>Just send BNB directly to the Pre Sale Contract</p>
      <p>Pre Sale Contract Address: </p>
      <p> {addressPreSaleContract} </p>
      <hr></hr>
      <h2>Import NEO Token into Metamask</h2>
      <p>Neo Token Contract Address: </p>
      <p>  {addressNeoContract}</p>
    </div>
  );
}
