"use client";
import React from "react";
import { Web3Provider } from "./Web3ProviderDir/Web3Provider";
import { ConnectKitButton } from "connectkit";
import { Web3Status } from "./Web3ProviderDir/Web3Status";

export function ShowMetamask({initializeMetamask}) {
  return (
    <div>

    <Web3Provider>
      <ConnectKitButton />
      <Web3Status initializeMetamask={initializeMetamask}/>
    </Web3Provider>
    </div>
  );
}
