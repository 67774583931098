"use client";
import { Status1 } from "./Status1";
import { useAccount } from "wagmi";

export function Web3Status({ initializeMetamask }) {
  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  if (isDisconnected) {
    initializeMetamask(false);
  }
  if (isConnected) {
    initializeMetamask(true);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "end",
        justifyContent: "end",
        width: "10rem",
        backgroundColor: "lightgrey",
        padding: "0.5rem",
        margin: "1rem",
        overflow: "auto",
        border: "1px solid",
        borderRadius: "1rem",
      }}
    >
      <b>MetaMask</b>
      <div style={{ marginBottom: "1rem" }}>
        <Status1 isConnecting={isConnecting} isDisconnected={isDisconnected} isConnected={isConnected} address={address} />
      </div>
    </div>
  );
}
