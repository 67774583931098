import { getDefaultConfig } from "connectkit";
import { createConfig, http } from "wagmi";
import { mainnet, bscTestnet, bsc } from "wagmi/chains";


export const config = createConfig(
    getDefaultConfig({
      // Your dApps chains
      chains: [bscTestnet, bsc, mainnet],
      pollingInterval: 5000,      
      transports: {
        // RPC URL for each chain
        [mainnet.id]: http(`https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYID}`),
        [bscTestnet.id]: http(`https://data-seed-prebsc-1-s1.bnbchain.org:8545`),
        [bsc.id]: http(`https://bsc-dataseed.bnbchain.org`),
      },
  
      // Required API Keys
      walletConnectProjectId: "8d39017dc1a4035a650f43b72ae7e235",
      autoConnect: true,
      // Required App Info
      appName: "TestiPhone",
  
      // Optional App Info
      appDescription: "Your App Description",
      appUrl: "https://bioalternativa.co", // your app's url
      appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    })
  );