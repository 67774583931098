import { React, useRef, useState, useEffect } from "react";
import "./App.css";
// import { HelloWalletComponent } from "./HelloWalletComponent";

import BEP20USDTArtifact from "../contracts/BEP20USDT.json";
import Coin1Artifact from "../contracts/Coin1.json";
import PreSaleArtifact from "../contracts/PreSale.json";
import BurnCoin1Artifact from "../contracts/BurnCoin1.json";
import StakingVaultArtifact from "../contracts/StakingVault.json";
import VestingArtifact from "../contracts/Vesting.json";
import MarketingArtifact from "../contracts/Marketing.json";
import IUniswapV2Router02Artifact from "../contracts/IUniswapV2Router02.json";

import { NoWalletDetected } from "./NoWalletDetected";
// import { TransactionErrorMessage } from "./TransactionErrorMessage";
// import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
// // import { NoTokensMessage } from "./NoTokensMessage";
import { PreSale } from "./PreSale";
import { BurnNeo } from "./BurnNeo";
import { Staking } from "./Staking";
import { PancakeSwapLP } from "./PancakeSwapLP";
import { SwapMORPH2NEO } from "./SwapMORPH2NEO";
import { Overview } from "./Overview";
import { MainOverview } from "./MainOverview";

import { ShowMetamask } from "./ShowMetamask";
// import { mainnet, bsc, bscTestnet } from "viem/chains";
// import { getContract, formatEther, parseEther, createWalletClient, custom, createPublicClient, http } from "viem";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { getBalance } from "@wagmi/core";
import { config } from "./config";

// import { config } from './config'
import contractAddress from "../contracts/Addresses_bsctestnet.json";
// import contractAddress from "../contracts/Addresses_localhost.json";
// const HARDHAT_NETWORK_ID = 31337;
// const HARDHAT_NETWORK_ID = "38"; // 0x38 = 56 BNB Smart Chain MAINNET
const HARDHAT_NETWORK_ID = 97; // 0x61 = 97 BNB Smart Chain TESTNET
// const HARDHAT_NETWORK_ID = 1; // this is localhost ETH mainnet forked

const neoHeader = require("../images/header.png");
const neoInu = require("../images/neo_logo2-1024x1024.png");

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const queryClient = new QueryClient();

export function App() {
  // const initializing = useRef(true);

  // useEffect(() => {
  //   if (initializing.current) {
  //     initializing.current = false;
  //     // console.log("Metamask account = ")
  //     // _initializeMetamask()

  //     // if (pollDataInterval.current === "undefined") {
  //     //   // pollDataInterval.current = setInterval(() => _updateBalance(), 1000);
  //     // } else {
  //     //   // console.log("Interval already set");
  //     // }
  //   } else {
  //     // console.log("Already initialized");
  //   }
  // }, []);

  // _startPollingData();
  // function _startPollingData() {
  //   console.log("Setting up the Interval for polling User Balances");
  //   setPollDataInterval(setInterval(() => _updateBalance(), 1000));

  //   // // We run it once immediately so we don't have to wait for it
  //   _updateBalance();
  // }
  // let provider;
  // const [provider, setProvider] = useState(undefined)

  // let _bep20USDT = useRef( undefined);
  // let _token = useRef( undefined);
  // let _uniswapRouter02 = useRef(undefined);
  // let _vesting = useRef( undefined);
  // let _marketing = useRef( undefined);
  // let _burnCoin1 = useRef( undefined);
  // let _stakingVault = useRef( undefined);
  // let _router02 = useRef( undefined);
  // let _preSale = useRef( undefined);

  // let name = useRef(undefined);
  // let symbol = useRef(undefined);

  // const pollDataInterval = useRef(undefined);
  // const triggerUpdateRequired = useRef(true);

  const [activeTab, setActiveTab] = useState("tab1");
  const [connected, setConnected] = useState(false);
  const [uniswapRouter02Address, setUniswapRouter02Address] = useState("");

  const [preSaleTokenPerWei, setPreSaleTokenPerWei] = useState(0);
  const [preSaleTokenPerUSDTcent, setPreSaleTokenPerUSDTcent] = useState(0);
  

  const [userBalanceEth, setUserBalanceEth] = useState(0);
  const [userBalanceNeo, setUserBalanceNeo] = useState(0);
  const [userBalanceMorph, setUserBalanceMorph] = useState(0);
  const [userBalanceUsdt, setUserBalanceUsdt] = useState(0);

  function _provideBalanceData(_useBalanceETH, _useBalanceNEO, _useBalanceMORPH, _useBalanceUSDT) {
    setUserBalanceEth(_useBalanceETH);
    setUserBalanceNeo(_useBalanceNEO);
    setUserBalanceMorph(_useBalanceMORPH);
    setUserBalanceUsdt(_useBalanceUSDT);
  }

  function _switchTab(tab) {
    var elem = document.getElementById(tab);
    // console.log("activeTab", activeTab);
    // console.log("tab", tab);
    if (tab !== activeTab) {
      var elemPrev = document.getElementById(activeTab);
      elemPrev.classList.remove("selectedTab");
      elem.classList.add("selectedTab");
      setActiveTab(tab);
    }
  }

  function _updateAddressUniswapRouter02(uniswapRouterAddr) {
    setUniswapRouter02Address(uniswapRouterAddr);
  }
  function _initializeMetamask(_connected) {
    setConnected(_connected);
  }

  function _providePreSaleData(_preSaleTokenPerWei, _preSaleTokenPerUSDTcent) {
    setPreSaleTokenPerWei(_preSaleTokenPerWei);
    setPreSaleTokenPerUSDTcent(_preSaleTokenPerUSDTcent);
  }
  return (
    <div className="container p-4">
      <div className="row">
        <div className="col-1">
          <img src={neoHeader} alt="Header"></img>
        </div>
        <div className="col-11">
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <ShowMetamask initializeMetamask={(_connected) => _initializeMetamask(_connected)} />
            </QueryClientProvider>
          </WagmiProvider>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-12">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button type="button" id="tab1" className="btn btn-success selectedTab mx-1" onClick={() => _switchTab("tab1")}>
              Pre Sale
            </button>
            <button type="button" id="tab2" className="btn btn-success mx-1" onClick={() => _switchTab("tab2")}>
              Burn NEO
            </button>
            <button type="button" id="tab3" className="btn btn-success mx-1" onClick={() => _switchTab("tab3")}>
              Staking Pool
            </button>
            <button type="button" id="tab4" className="btn btn-success mx-1" onClick={() => _switchTab("tab4")}>
              Pancake Swap
            </button>
            <button type="button" id="tab5" className="btn btn-success mx-1" onClick={() => _switchTab("tab5")}>
              Swap MORPH to NEO
            </button>
            <button type="button" id="tab6" className="btn btn-success mx-1" onClick={() => _switchTab("tab6")}>
              Overview
            </button>
          </div>
        </div>

        <div className="containerNeoLogo col-6">
          {connected && (
            <div className="MainOverview">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <MainOverview
                    abiNeoContract={Coin1Artifact}
                    addressNeoContract={contractAddress.Coin1}
                    abiMorphContract={BurnCoin1Artifact}
                    addressMorphContract={contractAddress.BurnCoin1}
                    abiStakingContract={StakingVaultArtifact}
                    addressStakingContract={contractAddress.StakingVault}
                    abiPreSaleContract={PreSaleArtifact}
                    addressPreSaleContract={contractAddress.PreSale}
                    addressUsdtContract={contractAddress.BEP20USDT}
                    updateAddressUniswapRouter02={(uniswapRouterAddr) => _updateAddressUniswapRouter02(uniswapRouterAddr)}
                    providePreSaleData={(preSaleTokenPerWei, preSaleTokenPerUSDTcent) =>
                      _providePreSaleData(preSaleTokenPerWei, preSaleTokenPerUSDTcent)
                    }
                    provideBalanceData={(_useBalanceETH, _useBalanceNEO, _useBalanceMORPH, _useBalanceUSDT) =>
                      _provideBalanceData(_useBalanceETH, _useBalanceNEO, _useBalanceMORPH, _useBalanceUSDT)
                    }
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
          )}
          <div className="Image">
            <img src={neoInu} alt="Neo Logo"></img>
          </div>
        </div>
        <div className="containerTransfer col-6">
          {/* {initialState.tokenBalance.gt(0) && <NoTokensMessage selectedAddress={accounts[0]} />} */}
          {!connected && (
            <div className="NoWalletDetected">
              <NoWalletDetected addressPreSaleContract={contractAddress.PreSale} addressNeoContract={contractAddress.Coin1} />
            </div>
          )}

          {activeTab === "tab1" && connected && (
            <div className="PreSale">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <PreSale
                    abiNeoContract={Coin1Artifact}
                    addressNeoContract={contractAddress.Coin1}
                    abiPreSaleContract={PreSaleArtifact}
                    addressPreSaleContract={contractAddress.PreSale}
                    abiUsdtContract={BEP20USDTArtifact}
                    addressUsdtContract={contractAddress.BEP20USDT}
                    tokenPerWei={preSaleTokenPerWei}
                    tokenPerUSDTcent={preSaleTokenPerUSDTcent}
                    userBalanceEth={userBalanceEth}
                    userBalanceNeo={userBalanceNeo}
                    userBalanceUsdt={userBalanceUsdt}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
          )}
          {/* {activeTab === "tab1" && connected && (
            <PreSale
              contributeUSDT={(amountUSDTBigInt) => _contributeUSDT(amountUSDTBigInt)}
              contributeETH={(amountETH) => _contributeETH(amountETH)}
              coin1PreSaleBalance={preSaleState.coin1PreSaleBalance}
              tokenPerWei={preSaleState.tokenPerWei}
              tokenPerUSDTcent={preSaleState.tokenPerUSDTcent}
              preSaleStarted={initialState.preSaleStarted}
              preSaleEnded={initialState.preSaleEnded}
              preSaleSoldOut={initialState.preSaleSoldOut}
              neoPreSaleStart={initialState.neoPreSaleStart}
              neoPreSaleEnd={initialState.neoPreSaleEnd}
              tokensSold={preSaleState.tokensSold}
              ethContributed={preSaleState.ethContributed}
              usdtContributed={preSaleState.usdtContributed}
              userBalanceEth={initialState.ethBalance}
              userBalanceUsdt={initialState.usdtBalance}
              userBalanceToken={initialState.neoBalance}
            />
          )} */}
          {activeTab === "tab2" && connected && (
            <div className="BurnNeo">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <BurnNeo
                    abiNeoContract={Coin1Artifact}
                    addressNeoContract={contractAddress.Coin1}
                    abiMorphContract={BurnCoin1Artifact}
                    addressMorphContract={contractAddress.BurnCoin1}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
          )}
          {activeTab === "tab3" && connected && (
            <div className="Staking">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <Staking
                    abiStakingContract={StakingVaultArtifact}
                    addressStakingContract={contractAddress.StakingVault}
                    abiMorphContract={BurnCoin1Artifact}
                    addressMorphContract={contractAddress.BurnCoin1}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
          )}
          {activeTab === "tab4" && connected && (
            <div className="PancakeSwapLP">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <PancakeSwapLP
                    abiStakingContract={StakingVaultArtifact}
                    addressStakingContract={contractAddress.StakingVault}
                    abiNeoContract={Coin1Artifact}
                    addressNeoContract={contractAddress.Coin1}
                    abiIUniswapV2Router02={IUniswapV2Router02Artifact}
                    addressUniswapRouter02={uniswapRouter02Address}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
            // <PancakeSwapLP
            //   pancakeSwapETH2NEO={(amountETH) => _pancakeSwapETH2NEO(amountETH)}
            //   previewSwapETH2NEO={(amountETH) => _previewSwapETH2NEO(amountETH)}
            //   pancakeSwapNEO2ETH={(amountNEO) => _pancakeSwapNEO2ETH(amountNEO)}
            //   previewSwapNEO2ETH={(amountNEO) => _previewSwapNEO2ETH(amountNEO)}
            //   previewETHamount={initialState.previewAmountETH}
            //   previewNEOamount={initialState.previewAmountNEO}
            //   ethBalance={initialState.ethBalance}
            //   neoBalance={initialState.neoBalance}
            //   lpReserveETH={initialState.lpReserveETH}
            //   lpReserveNEO={initialState.lpReserveNEO}
            // />
          )}
          {activeTab === "tab5" && connected && (
            <div className="SwapMorph2Neo">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <SwapMORPH2NEO
                    abiNeoContract={Coin1Artifact}
                    addressNeoContract={contractAddress.Coin1}
                    abiMorphContract={BurnCoin1Artifact}
                    addressMorphContract={contractAddress.BurnCoin1}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
          )}
          {activeTab === "tab6" && connected && (
            <div className="Overview">
              <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                  <Overview
                    abiStakingContract={StakingVaultArtifact}
                    addressStakingContract={contractAddress.StakingVault}
                    abiMorphContract={BurnCoin1Artifact}
                    addressMorphContract={contractAddress.BurnCoin1}
                  />
                </QueryClientProvider>
              </WagmiProvider>
            </div>
            // <Overview
            //   tokenSymbol={symbol.current}
            //   userBalanceEth={initialState.ethBalance}
            //   userBalanceUsdt={initialState.usdtBalance}
            //   userBalanceNEO={initialState.neoBalance}
            //   userBalanceMorph={initialState.morphBalance}
            //   stakedShares={initialState.stakedShares}
            //   lpReserveETH={initialState.lpReserveETH}
            //   lpReserveNEO={initialState.lpReserveNEO}
            //   vestingPoolMorphBalance={initialState.vestingPoolMorphBalance}
            //   stakingPoolMorphBalance={initialState.stakingPoolMorphBalance}
            //   triggerCoin1Balance={initialState.triggerCoin1Balance}
            //   marketingCoin1Balance={initialState.marketingCoin1Balance}
            //   burnFee={initialState.burnFee}
            //   minBalanceSwapToken={initialState.minBalanceSwapToken}
            //   burnCountdown={initialState.burnCountdown}
            // />
          )}
          {/*
            This component displays a form that the user can use to send a 
            transaction and transfer some tokens.
            The component doesn't have logic, it just calls the transferTokens
            callback.
          */}
          {/* {this.activeTab === "tab7" && <Transfer transferTokens={(to, amount) => this._transferTokens(to, amount)} tokenSymbol={tokenData.symbol} />} */}
          {/*initialState.tokenBalance.eq(0) && <Transfer transferTokens={(to, amount) => this._transferTokens(to, amount)} tokenSymbol={tokenData.symbol} />*/}
        </div>
      </div>
    </div>
  );
  // async function _initializeMetamask() {
  //   // const balance = useBalance();

  //   // console.log("Provider(", provider)
  //   // if (typeof provider !== 'undefined') {

  //   if (triggerUpdateRequired.current === true) {
  //     console.log("triggerUpdateRequired1", triggerUpdateRequired.current);
  //     triggerUpdateRequired.current = false;

  //     console.log("triggerUpdateRequired2", triggerUpdateRequired.current);

  //     if (chainId === HARDHAT_NETWORK_ID) {
  //       // setInitialState((previousState) => {
  //       //   return { ...previousState, selectedAddress: accounts[0] };
  //       // });

  //       // console.log("selected Address:", accounts[0]);

  //       // try {
  //       //   console.log("Initialize Ethers...");
  //       //   await _initializeEthers();
  //       // } catch (error) {
  //       //   console.log("Error initialize Ethers", error);
  //       // }
  //       // try {
  //       //   console.log("Getting NeoInu (NEO) Token Data...");
  //       //   await _getTokenData();
  //       // } catch (error) {
  //       //   console.log("Error Getting NeoInu (NEO) Token Data", error);
  //       // }
  //       // try {
  //       //   console.log("Getting PreSale Smart Contract Data ...");
  //       //   await _getPreSaleData();
  //       // } catch (error) {
  //       //   console.log("Error Getting PreSale Smart Contract Data", error);
  //       // }
  //       // if (typeof pollDataInterval === "undefined") {
  //       //   console.log("Setting up the Interval for polling User Balances");
  //       //   _startPollingData();
  //       // } else {
  //       //   console.log("Interval already set");
  //       // }
  //     }
  //   }
  //   // }
  // }

  // async function _getTokenData() {
  // console.log("Getting Token Data ...");
  // try {
  //   name.current = await _token.current.read.name();
  //   symbol.current = await _token.current.read.symbol();
  // } catch (e) {
  //   console.log("e", e);
  //   // triggerUpdateRequired = true;
  // }
  // }
  // async function _getPreSaleData() {
  //   try {
  // const _preSaleStartTimestamp = await contracts._preSale.current.read.preSaleStartTimestamp();
  // const _preSaleEndTimestamp = await contracts._preSale.current.read.preSaleEndTimestamp();
  // const _tokenPerUSDTcent = await contracts._preSale.current.read.tokenPerUSDTcent();
  // const _tokenPerWei = await contracts._preSale.current.read.tokenPerWei();
  // const _ethReceiver = await contracts._preSale.current.read.ethReceiver();
  // const _usdtReceiver = await contracts._preSale.current.read.usdtReceiver();
  // setPreSaleState((previousState) => {
  //   return {
  //     ...previousState,
  //     preSaleStartTimestamp: _preSaleStartTimestamp,
  //     preSaleEndTimestamp: _preSaleEndTimestamp,
  //     tokenPerUSDTcent: _tokenPerUSDTcent,
  //     tokenPerWei: _tokenPerWei,
  //     ethReceiver: _ethReceiver,
  //     usdtReceiver: _usdtReceiver,
  //   };
  // });
  //   } catch (e) {
  //     console.log("e", e);
  //     // triggerUpdateRequired= true;
  //   }
  // }

  // async function _updateBalance() {
  //   try {
  //     let _neoBalance = await _token.current.read.balanceOf([accounts[0].toString()]);
  //     _neoBalance = formatEther(_neoBalance);

  //     let _morphBalance = await _burnCoin1.current.read.balanceOf([accounts[0].toString()]);
  //     _morphBalance = formatEther(_morphBalance);

  //     let _usdtBalance = await _bep20USDT.current.read.balanceOf([accounts[0].toString()]);
  //     _usdtBalance = formatEther(_usdtBalance);

  //     let _ethBalance = await getBalance(config, { address: accounts[0].toString() });
  //     _ethBalance = Number.parseFloat(formatEther(_ethBalance["value"])).toFixed(2);

  //     const _vestingPoolMorphBalance = await _burnCoin1.current.read.balanceOf([contractAddress.Vesting.toString()]);

  //     setInitialState((previousState) => {
  //       return {
  //         ...previousState,
  //         neoBalance: _neoBalance,
  //         morphBalance: _morphBalance,
  //         vestingPoolMorphBalance: _vestingPoolMorphBalance,
  //         ethBalance: _ethBalance,
  //         usdtBalance: _usdtBalance,
  //       };
  //     });

  //     const _myStakedShares = await _stakingVault.current.read.balanceOf([accounts[0].toString()]);

  //     const _coin1PreSaleBalance = await _token.current.read.balanceOf([contractAddress.PreSale.toString()]);
  //     setPreSaleState((previousState) => {
  //       return { ...previousState, coin1PreSaleBalance: _coin1PreSaleBalance };
  //     });

  //     let _stakedTotal = await _burnCoin1.current.read.totalSupply(); // total amount of staking Shares that had been converted from NEO Burn

  //     let _stakedPercentage = (Number.parseFloat(formatEther(_myStakedShares)).toFixed(2) * 100) / formatEther(_stakedTotal);
  //     _stakedTotal = Number.parseFloat(formatEther(_stakedTotal)).toFixed(2);
  //     // initialState.stakedTotal = _stakedTotal;

  //     const _stakedShares = Number.parseFloat(formatEther(_myStakedShares)).toFixed(2);
  //     setInitialState((previousState) => {
  //       return { ...previousState, stakedTotal: _stakedTotal, stakedShares: _stakedShares, stakedPercentage: _stakedPercentage.toFixed(2) };
  //     });

  //     let _stakedSharesMorphBalance = await _stakingVault.current.read.previewRedeem([_myStakedShares.toString()]);
  //     let _stakedSharesMorphYield = Number(parseFloat(((BigInt(_stakedSharesMorphBalance) - BigInt(_myStakedShares)) / BigInt(_myStakedShares)) * BigInt(100)).toString()).toFixed(
  //       2
  //     );
  //     _stakedSharesMorphBalance = Number.parseFloat(formatEther(_stakedSharesMorphBalance)).toFixed(2);

  //     setInitialState((previousState) => {
  //       return { ...previousState, stakedSharesMorphBalance: _stakedSharesMorphBalance, stakedSharesMorphYield: _stakedSharesMorphYield };
  //     });

  //     const _totalSupplyNeo = await _token.current.read.totalSupply();
  //     const _tokensSold = await _preSale.current.read.tokensSold();
  //     const _ethContributed = await _preSale.current.read.ethContributed();
  //     const _usdtContributed = await _preSale.current.read.usdtContributed();
  //     const _ethContributedAddress = await _preSale.current.read.ethContributedAddress([accounts[0].toString()]);
  //     const _usdtContributedAddress = await _preSale.current.read.usdtContributedAddress([accounts[0].toString()]);
  //     const _isPreSaleActive = await _preSale.current.read.isPreSaleActive();

  //     const _preSaleSoldOut = await _token.current.read.balanceOf([contractAddress.PreSale.toString()]);

  //     setInitialState((previousState) => {
  //       return { ...previousState, preSaleSoldOut: BigInt(_preSaleSoldOut) === BigInt(0) };
  //     });

  //     const _stakingPoolMorphBalance = await _burnCoin1.current.read.balanceOf([contractAddress.StakingVault.toString()]);
  //     const _triggerCoin1Balance = await _token.current.read.balanceOf([contractAddress.Coin1.toString()]);
  //     const _marketingCoin1Balance = await _token.current.read.balanceOf([contractAddress.Marketing.toString()]);

  //     setInitialState((previousState) => {
  //       return { ...previousState, stakingPoolMorphBalance: _stakingPoolMorphBalance, triggerCoin1Balance: _triggerCoin1Balance, marketingCoin1Balance: _marketingCoin1Balance };
  //     });

  //     const _burnFee = await _token.current.read.burnFee();
  //     const _minBalanceSwapToken = await _token.current.read.minBalanceSwapToken();
  //     // console.log("_minBalanceSwapToken",formatEther(_minBalanceSwapToken));
  //     // console.log("_triggerCoin1Balance",formatEther(_triggerCoin1Balance));
  //     /* global BigInt */
  //     // var _burnCountdown = await _token.current.read.burnTriggerCountdown(); // (BigInt(_minBalanceSwapToken) - BigInt(_triggerCoin1Balance)) / BigInt(10 ** 18);
  //     var _burnCountdown;
  //     if (BigInt(_triggerCoin1Balance) > BigInt(_minBalanceSwapToken)) {
  //       _burnCountdown = 0;
  //     } else {
  //       _burnCountdown = (BigInt(_minBalanceSwapToken) - BigInt(_triggerCoin1Balance)) / BigInt(10 ** 18);
  //     }

  //     const reserves = await _token.current.read.getReservesOfLP();
  //     setInitialState((previousState) => {
  //       return {
  //         ...previousState,
  //         burnFee: _burnFee.toString(),
  //         minBalanceSwapToken: formatEther(_minBalanceSwapToken),
  //         burnCountdown: _burnCountdown.toString(),
  //         lpReserveETH: formatEther(reserves[1]),
  //         lpReserveNEO: formatEther(reserves[0]),
  //       };
  //     });

  //     _setPreSaleData();
  //     setPreSaleState((previousState) => {
  //       return {
  //         ...previousState,
  //         tokensSold: formatEther(_tokensSold),
  //         isPreSaleActive: Boolean(_isPreSaleActive),
  //         usdtContributedAddress: _usdtContributedAddress.toString(),
  //         ethContributedAddress: _ethContributedAddress.toString(),
  //         usdtContributed: formatEther(_usdtContributed),
  //         ethContributed: formatEther(_ethContributed),
  //       };
  //     });

  //     // Amount of neos burned can not come from the dead wallet as morph is minted and can be swapped back to NEO increasing neo in circulation
  //     // a total amount of 500MM NEO was created (30MM as COIN2)
  //     // Vesting 30MM COIN2
  //     // Marketing 120MM COIN1
  //     // Pancake Swap 50MM COIN1
  //     // PreSale 300MM COIN1
  //     //

  //     // const _neosDead = await _token.current.read.balanceOf([ "0x000000000000000000000000000000000000dead"]);
  //     // var _neosBurned = await _token.current.read.balanceOf(["0x0000000000000000000000000000000000000001"]);
  //     var _neosBurned = await _token.current.read.balanceOf(["0x000000000000000000000000000000000000dead"]);

  //     // let _neosBurnedPercentage = BigInt(_neosBurned / parseEther("500000000")) * BigInt(100)

  //     let _neosBurnedPercentage = parseFloat(((Number.parseFloat(formatEther(_neosBurned)) / 500000000) * 100).toFixed(2));

  //     _neosBurned = Number.parseFloat(formatEther(_neosBurned)).toFixed(2);
  //     setInitialState((previousState) => {
  //       return { ...previousState, neosBurnedPercentage: _neosBurnedPercentage.toString(), neosBurned: _neosBurned.toString() };
  //     });
  //   } catch (err) {
  //     console.log("_updateBalance error", err);
  //     // triggerUpdateRequired = true;
  //   }
  // }

  // function _stopPollingData() {
  //   clearInterval(pollDataInterval.current);
  //   pollDataInterval.current = undefined;
  // }
  // async function _contributeUSDT(amountUSDTBigInt) {
  //   console.log(`User wants to buy NEO with ${amountUSDTBigInt} USDT`);
  //   try {
  //     _dismissTransactionError();

  //     const txApprove = await _bep20USDT.write.approve([contractAddress.PreSale, amountUSDTBigInt.toString()]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txApprove.hash };
  //     });

  //     // const receipt1 = await txApprove.wait();
  //     const receipt1 = await provider.waitForTransactionReceipt({ hash: txApprove.hash });

  //     if (receipt1.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     const txContributeUSDT = await _preSale.write.contributeUSDT([amountUSDTBigInt.toString()]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txContributeUSDT.hash };
  //     });

  //     // const receipt2 = await txContributeUSDT.wait();
  //     const receipt2 = await provider.waitForTransactionReceipt({ hash: txContributeUSDT.hash });

  //     if (receipt2.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }
  //     // If we got here, the transaction was successful
  //     await _updateBalance();
  //     console.log("ContributeUSDT successfull!!");
  //   } catch (error) {
  //     console.error("ERROR CAUGHT:", error);
  //     if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
  //       return;
  //     }
  //     setInitialState((previousState) => {
  //       return { ...previousState, transactionError: error };
  //     });
  //   } finally {
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: undefined };
  //     });
  //   }
  // }
  // async function _contributeETH(amountWeiBigInt) {
  //   console.log(`User wants to buy NEO with ${amountWeiBigInt} ETH`);
  //   try {
  //     _dismissTransactionError();

  //     console.log("User wants to buy NEO ...", amountWeiBigInt.toString());

  //     const txContributeETH = await _preSale.write.contribute([{ value: amountWeiBigInt.toString() }]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txContributeETH.hash };
  //     });

  //     const receipt1 = await provider.waitForTransactionReceipt({ hash: txContributeETH.hash });
  //     if (receipt1.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     // If we got here, the transaction was successful
  //     await _updateBalance();
  //     console.log("ContributeETH successfull!!");
  //   } catch (error) {
  //     if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
  //       return;
  //     }
  //     console.error(error);
  //     setInitialState((previousState) => {
  //       return { ...previousState, transactionError: error };
  //     });
  //   } finally {
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: undefined };
  //     });
  //   }
  // }

  // async function _pancakeSwapETH2NEO(amountETH) {
  //   // Router LOCALHOST: 0xEfF92A263d31888d860bD50809A8D171709b7b1c
  //   const path0 = contractAddress.Coin1; // 0xD73bAb8F06DB28c87932571f87D0D2C0FDF13D94
  //   const path1 = await contracts._router02.current.read.WETH(); // 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2

  //   const timestamp = (await provider.getBlock("latest")).timestamp;
  //   // console.log("timestamp", timestamp);
  //   const _value = parseEther(amountETH).toString();
  //   // console.log("_value", _value);

  //   try {
  //     _dismissTransactionError();

  //     const txETH2TOKEN = await contracts._router02.write.swapExactETHForTokensSupportingFeeOnTransferTokens([
  //       0,
  //       [path1, path0],
  //       accounts[0],
  //       timestamp + 500,
  //       { value: _value },
  //     ]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txETH2TOKEN.hash };
  //     });
  //     const receipt = await provider.waitForTransactionReceipt({ hash: txETH2TOKEN.hash });
  //     if (receipt.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     // If we got here, the transaction was successful
  //     await _updateBalance();
  //     console.log("Swapped ETH to NEO successfull!!");
  //   } catch (error) {
  //     if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
  //       return;
  //     }
  //     console.error(error);
  //     setInitialState((previousState) => {
  //       return { ...previousState, transactionError: error };
  //     });
  //   } finally {
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: undefined };
  //     });
  //   }
  // }
  // async function _pancakeSwapNEO2ETH(amountNEO) {
  //   const path0 = contractAddress.Coin1; // 0xD73bAb8F06DB28c87932571f87D0D2C0FDF13D94
  //   const path1 = await contracts._router02.current.read.WETH(); // 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
  //   console.log("path0", path0);
  //   console.log("path1", path1);
  //   const _value = parseEther(amountNEO).toString();
  //   console.log("_value", _value);

  //   const uniswapRouterAddress = await contracts._token.current.read.uniswapRouter();
  //   try {
  //     _dismissTransactionError();

  //     const txApprove = await contracts._token.write.approve([uniswapRouterAddress, _value]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txApprove.hash };
  //     });
  //     const receipt = await provider.waitForTransactionReceipt({ hash: txApprove.hash });
  //     if (receipt.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     const timestamp = (await provider.getBlock("latest")).timestamp;
  //     console.log("timestamp", timestamp);

  //     const txTOKEN2ETH = await contracts._router02.write.swapExactTokensForETHSupportingFeeOnTransferTokens([
  //       _value, //  Sender sends amount of Tokens
  //       0, // accept any amount of ETH
  //       [path0, path1],
  //       accounts[0], //  Sender receives the ETH
  //       timestamp + 500,
  //     ]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txTOKEN2ETH.hash };
  //     });
  //     const receipt1 = await provider.waitForTransactionReceipt({ hash: txTOKEN2ETH.hash });
  //     if (receipt1.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     await _updateBalance();
  //     console.log("Swapped NEO to ETH successfull!!");
  //   } catch (error) {
  //     if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
  //       return;
  //     }
  //     console.error(error);
  //     setInitialState((previousState) => {
  //       return { ...previousState, transactionError: error };
  //     });
  //   } finally {
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: undefined };
  //     });
  //   }
  // }
  // async function _swapMorph2Neo(amountMORPHBigInt) {
  //   console.log("Amount MORPH to swap into NEO (BigInt):", amountMORPHBigInt);
  //   try {
  //     _dismissTransactionError();

  //     const walletClient = createWalletClient({
  //       chain: bscTestnet,
  //       // transport: http(`https://bsc-testnet-rpc.publicnode.com`),
  //       transport: custom(window.ethereum),
  //     });
  //     const publicClient = createPublicClient({
  //       chain: bscTestnet,
  //       transport: http(`https://bsc-testnet-rpc.publicnode.com`),
  //     });
  //     const [account] = await walletClient.getAddresses();
  //     // console.log("amountMORPHBigInt.toString()", amountMORPHBigInt.toString());
  //     const { request } = await publicClient.simulateContract({
  //       account,
  //       address: contractAddress.Coin1,
  //       abi: Coin1Artifact,
  //       functionName: "swapCoin2toCoin1Public",
  //       args: [amountMORPHBigInt.toString()],
  //       // gas: 66000n,
  //     });
  //     const txMorph2NeoHash = await walletClient.writeContract(request);

  //     console.log("txMorph2Neo", txMorph2NeoHash);
  //     // const txMorph2Neo = await contracts._token.write.swapCoin2toCoin1Public([amountMORPHBigInt.toString()]);
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: txMorph2NeoHash };
  //     });
  //     const receipt = await publicClient.waitForTransactionReceipt({ hash: txMorph2NeoHash });

  //     if (receipt.status !== "success") {
  //       throw new Error("Transaction failed");
  //     }

  //     // If we got here, the transaction was successful
  //     await _updateBalance();
  //     console.log("Swapping MORPH into NEO successfull!!");
  //   } catch (error) {
  //     if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
  //       return;
  //     }
  //     console.error(error);
  //     setInitialState((previousState) => {
  //       return { ...previousState, transactionError: error };
  //     });
  //   } finally {
  //     setInitialState((previousState) => {
  //       return { ...previousState, txBeingSent: undefined };
  //     });
  //   }
  // }
}

export default App;
