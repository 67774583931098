import { React, useState, useEffect, useRef } from "react";
import { formatEther } from "viem";
import { useWriteContract, useWaitForTransactionReceipt, useAccount, useReadContract, useWatchContractEvent, useWatchBlockNumber } from "wagmi";

export function Staking({ abiStakingContract, addressStakingContract, abiMorphContract, addressMorphContract }) {
  
  const [previewStakingShares, setPreviewStakingShares] = useState("0");
  const [previewMorph, setPreviewMorph] = useState("0");


  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const blockNumber = useRef();
  const walletAddress = useAccount();
  const [userAmountMORPH, setUserAmountMorph] = useState(0);
  const [userAmountStakingShares, setUserAmountStakingShares] = useState(0);
  const [updated, setUpdated] = useState(0);

  const _amountMorphToStakeBigInt = useRef(BigInt(0));
  const _amountStakingSharesToRedeemBigInt = useRef(BigInt(0));
  const StakingSharesPrev = useRef(BigInt(0));
  const MorphPrev = useRef(BigInt(0));

  const morphBalance = useRef("0");
  const stakingSharesBalance = useRef("0");
  const stakingPreviewShares = useRef("0");
  const stakingPreviewMorph = useRef("0");
  const loading = useRef(true);
  const afterTransaction = useRef(false);

  const [infoStakeMORPH, setInfoStakeMORPH] = useState("0");
  const [infoRedeemShares, setInfoRedeemShares] = useState("0");

  const [btnClicked, setBtnClicked] = useState("");

  useEffect(() => {
    //console.log("walletAddress", walletAddress.address);
    //Runs on the first render
    //And any time any dependency value changes

    if (morphBalance.current !== "0") {
      setUserAmountMorph(morphBalance.current);
    }
    if (stakingSharesBalance.current !== "0") {
      setUserAmountStakingShares(stakingSharesBalance.current);
    }
    if (stakingPreviewShares.current !== "0") {
      setPreviewStakingShares(stakingPreviewShares.current);
    }
    if (stakingPreviewMorph.current !== "0") {
      setPreviewMorph(stakingPreviewMorph.current);
    }
  }, [updated]);

  useWatchContractEvent({
    abi: abiMorphContract,
    address: addressMorphContract,
    eventName: "Transfer",
    onLogs(logs) {
      console.log("Morph Transfer (Deposit or Withdraw) logged!", logs);
      afterTransaction.current = true;
      setUpdated(updated + 1);
    },
  });

  useWatchBlockNumber({
    onBlockNumber(blockNumber1) {
      // if(afterTransaction.current) {
      blockNumber.current = blockNumber1;
      setUpdated(updated + 1);
      // }
    },
  });

  const _morphResult = useReadContract({
    abi: abiMorphContract,
    address: addressMorphContract,
    functionName: "balanceOf",
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  });
  const _stakingResult = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "balanceOf",
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  });

  const _stakingPreviewResult = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "previewDeposit",
    args: [_amountMorphToStakeBigInt.current],
    blockNumber: blockNumber.current,
  });
  
  const _redeemPreviewResult = useReadContract({
    abi: abiStakingContract,
    address: addressStakingContract,
    functionName: "previewRedeem",
    args: [_amountStakingSharesToRedeemBigInt.current],
    blockNumber: blockNumber.current,
  });

  // To get the Preview Result in one click and not in two!
  if((_amountStakingSharesToRedeemBigInt.current !== StakingSharesPrev.current && _redeemPreviewResult.isSuccess) ||
  (_amountMorphToStakeBigInt.current !== MorphPrev.current && _stakingPreviewResult.isSuccess)) {
    StakingSharesPrev.current =_amountStakingSharesToRedeemBigInt.current;
    MorphPrev.current =_amountMorphToStakeBigInt.current;
    loading.current = true;
  }

  if (afterTransaction.current || loading.current) {
    // console.log("loading", loading.current);
    // console.log("afterTransaction", afterTransaction.current);
    if (_morphResult.isSuccess && _stakingResult.isSuccess) {
      afterTransaction.current = false;
      loading.current = false;
      morphBalance.current = formatEther(_morphResult.data.toString());

      stakingSharesBalance.current = formatEther(_stakingResult.data.toString());
      // console.log("_stakingResult", _stakingResult);
      // console.log("_morphResult", _morphResult);
      setUpdated(updated + 1);
    }
    if(_stakingPreviewResult.isSuccess) {
      afterTransaction.current = false;
      loading.current = false;
      stakingPreviewShares.current = formatEther(_stakingPreviewResult.data.toString());
      setUpdated(updated + 1);
    }

    if(_redeemPreviewResult.isSuccess) {
      afterTransaction.current = false;
      loading.current = false;
      stakingPreviewMorph.current = formatEther(_redeemPreviewResult.data.toString());
      setUpdated(updated + 1);
    }
  }

  const inputEvent1 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      /* global BigInt */
      var amountMORPHBigInt = BigInt(Number(parseFloat(e.target.value).toFixed(6) * 1000000)) * BigInt(10 ** 12);
      setInfoStakeMORPH(formatEther(amountMORPHBigInt.toString()));
    }
  };

  const inputEvent2 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      let amountSHARESBigInt = BigInt(Number(parseFloat(e.target.value).toFixed(6) * 1000000)) * BigInt(10 ** 12);
      setInfoRedeemShares(formatEther(amountSHARESBigInt.toString()));
    }
  };

  return (
    <div>
      <hr></hr>
      <h4>Staking</h4>
      <form
        onSubmit={async (event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();

          const formData = new FormData(event.target);
          const amountMorphToStake = formData.get("amountMorph");
          const amountSharesToRedeem = formData.get("amountShares");

          if (btnClicked === "Stake" || btnClicked === "PreviewStake") {
            formData.set("amountMORPH", 0);
            if (Number(amountMorphToStake) > 0) {
              var amountMorphToStakeBigInt = BigInt(Number.parseFloat(amountMorphToStake).toFixed(3) * 1000) * BigInt(10 ** 15);
              const stakeableMORPHBigInt = BigInt(Number.parseFloat(userAmountMORPH).toFixed(3) * 1000) * BigInt(10 ** 15);

              if (amountMorphToStakeBigInt <= stakeableMORPHBigInt) {
                if (btnClicked === "Stake") {
                  //console.log("btnClicked === Stake");
                  // stake(amountMorphToStakeBigInt);
                  writeContract({
                    address: addressMorphContract,
                    abi: abiMorphContract,
                    functionName: "stakeCoin2",
                    args: [amountMorphToStakeBigInt.toString()],
                  });
                } else {             
                  _amountMorphToStakeBigInt.current = amountMorphToStakeBigInt;
                }
                afterTransaction.current = true;                 
              } else {
                alert(`MORPH amount ${amountMorphToStake} exceeds MORPH Balance ${userAmountMORPH}`);
              }
            } else {
              alert("MORPH amount to stake required.");
            }
          }
          if (btnClicked === "Redeem" || btnClicked === "PreviewRedeem") {
            formData.set("amountShares", 0);
            if (Number(amountSharesToRedeem) > 0) {
              //
              var amountSharesToRedeemBigInt = BigInt(Number.parseFloat(amountSharesToRedeem).toFixed(3) * 1000) * BigInt(10 ** 15);
              const redeemableSHARESBigInt = BigInt(Number.parseFloat(userAmountStakingShares).toFixed(3) * 1000) * BigInt(10 ** 15);

              if (amountSharesToRedeemBigInt <= redeemableSHARESBigInt) {
                if (btnClicked === "Redeem") {
                  writeContract({
                    address: addressStakingContract,
                    abi: abiStakingContract,
                    functionName: "redeem",
                    args: [amountSharesToRedeemBigInt.toString(),walletAddress.address,walletAddress.address],
                  });
                  // console.log("btnClicked === Redeem");
                  // redeem(amountSharesToRedeemBigInt);
                } else {
                  _amountStakingSharesToRedeemBigInt.current = amountSharesToRedeemBigInt;
                }
                afterTransaction.current = true;
                
              } else {
                alert(`Shares amount ${amountSharesToRedeem} exceeds redeemable Shares ${userAmountStakingShares}`);
              }
            } else alert("Shares amount to redeem required.");
          }

          setBtnClicked("");
        }}
      >
        <hr></hr>
        <div className="form-group col-8">
          <h2>Stake your MORPH</h2>
          <input className="form-control" type="number" step="0.001" name="amountMorph" placeholder="0.001" onChange={inputEvent1} />
          <p>
            You are staking {infoStakeMORPH} of max {userAmountMORPH} MORPH
          </p>
          <hr></hr>
          <button className="btn btn-light mx-2" onClick={() => setBtnClicked("Stake")} disabled={isPending} type="submit">
            {isPending ? "Confirming..." : "Stake"}
          </button>
          <button className="btn btn-light mx-2" onClick={() => setBtnClicked("PreviewStake")} type="submit">
            PreviewStake
          </button>

          {hash && <div>Transaction Hash: {hash}</div>}
          {isConfirming && <div>Waiting for confirmation...</div>}
          {isConfirmed && <div>Transaction confirmed.</div>}
          <hr></hr>
 
          <p>Preview: You will receive {previewStakingShares} Staking Shares</p>
        </div>

        <hr></hr>

        <div className="form-group col-8">
          <h2>Redeem your Staking Shares (Unstake your MORPH)</h2>
          <input className="form-control" type="number" step="0.001" name="amountShares" placeholder="0.001" onChange={inputEvent2} />
          <p>
            You are redeeming {infoRedeemShares} of max {userAmountStakingShares} Staking Shares
          </p>
          <hr></hr>
          <button className="btn btn-light mx-2" onClick={() => setBtnClicked("Redeem")} disabled={isPending} type="submit">
            {isPending ? "Confirming..." : "Redeem"}
          </button>
          <button className="btn btn-light mx-2" onClick={() => setBtnClicked("PreviewRedeem")} type="submit">
            PreviewRedeem
          </button>

          {hash && <div>Transaction Hash: {hash}</div>}
          {isConfirming && <div>Waiting for confirmation...</div>}
          {isConfirmed && <div>Transaction confirmed.</div>}

          <hr></hr>
          <p>Preview: You will receive {previewMorph} MORPH</p>
        </div>
      </form>
      {/* <h5>Your Balance:</h5> */}
      {/* <p>{stakeableMORPH} MORPH stakeable</p>
      <p>{redeemableSHARES} Shares redeemable</p> */}
    </div>
  );
}
