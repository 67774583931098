import { React, useState, useEffect, useRef } from "react";
import { formatEther, parseEther } from "viem";
import { useWriteContract, useWaitForTransactionReceipt, useAccount, useBalance, useReadContract, useWatchContractEvent, useWatchBlocks } from "wagmi";

export function PancakeSwapLP({ abiStakingContract, addressStakingContract, abiNeoContract, addressNeoContract, abiIUniswapV2Router02, addressUniswapRouter02 }) {
  const [infoSwapETH, setInfoSwapETH] = useState(0);
  const [infoSwapNEO, setInfoSwapNEO] = useState(0);
  const [btnClicked, setBtnClicked] = useState("");
  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const walletAddress = useAccount();
  const [loadingBlockchainData, setLoadingBlockchainData] = useState(true);
  const [userAmountETH, setUserAmountETH] = useState("0");
  const [userAmountNEO, setUserAmountNEO] = useState("0");
  const [amountEthToSwapPrev, setAmountEthToSwapPrev] = useState("0");
  const [amountNeoToSwapPrev, setAmountNeoToSwapPrev] = useState("0");
  const [updated, setUpdated] = useState(0);
  const [approvalRequired, setApprovalRequired] = useState(true);
  // const [isSwappingETH2NEO, setIsSwappingETH2NEO] = useState(true);

  const blockNumber = useRef();
  const blockTimestamp = useRef(BigInt(0));
  const loading = useRef(true);
  const afterTransaction = useRef(false);
  // const approvAndSwap = useRef("approvalRequired");

  const _userAmountETH = useRef("0");
  const _userAmountNEO = useRef("0");
  const _amountEthToSwapPrev = useRef(BigInt(0));
  const _amountNeoToSwapPrev = useRef(BigInt(0));

  const _amountEthToSwapBigIntPrevious = useRef(BigInt(1));
  const _amountNeoToSwapBigIntPrevious = useRef(BigInt(1));

  const _amountEthToSwapBigInt = useRef(BigInt(1));
  const _amountNeoToSwapBigInt = useRef(BigInt(1));

  const [lpReserveETH, setLpReserveETH] = useState(0);
  const [lpReserveNEO, setLpReserveNEO] = useState(0);

  // const _abiPlaceholder = useRef(abiNeoContract);
  // const _addressUniswapRouter02 = useRef(undefined);
  // const _functionStringPlaceholder = useRef("totalSupply");
  const _weth = useRef(undefined);

  useEffect(() => {
    //console.log("walletAddress", walletAddress.address);
    //Runs on the first render
    //And any time any dependency value changes
    if (_userAmountETH.current !== "0") {
      setUserAmountETH(Number.parseFloat(_userAmountETH.current));
    }
    if (_userAmountNEO.current !== "0") {
      setUserAmountNEO(Number.parseFloat(_userAmountNEO.current));
    }
    if (_amountEthToSwapPrev.current !== BigInt(0)) {
      setAmountEthToSwapPrev(Number.parseFloat(_amountEthToSwapPrev.current).toFixed(0));
    }
    if (_amountNeoToSwapPrev.current !== BigInt(0)) {
      setAmountNeoToSwapPrev(Number.parseFloat(_amountNeoToSwapPrev.current).toFixed(3));
    }
  }, [updated]);

  useWatchContractEvent({
    abi: abiNeoContract,
    address: addressNeoContract,
    eventName: "Transfer",
    onLogs(logs) {
      console.log("Neo Transfer (Swapping) logged!", logs);
      afterTransaction.current = true;
      setUpdated(updated + 1);
    },
  });

  useWatchBlocks({
    onBlock(block) {
      console.log("New block Number", block.number);
      console.log("New block Timestamp", block.timestamp);
      blockNumber.current = block.number;
      blockTimestamp.current = block.timestamp;
      setUpdated(updated + 1);
    },
  });

  // useWatchBlockNumber({
  //   onBlockNumber(blockNumber1) {
  //     // if(afterTransaction.current) {
  //     blockNumber.current = blockNumber1;
  //     console.log("Blocknumber", blockNumber1);
  //     setUpdated(updated + 1);
  //     // }
  //   },
  // });

  const _useBalanceETH = useBalance({
    address: walletAddress.address,
  });
  const _useBalanceNEO = useBalance({
    address: walletAddress.address,
    token: addressNeoContract,
  });
  // async function _previewSwapETH2NEO(amountETH) {
  //   // console.log("ethers.utils.parseEther(amountETH)", ethers.utils.parseEther(amountETH).toString());
  //   const _previewAmountNEO = await contracts._token.current.read.previewSwapTokens([parseEther(amountETH).toString()]);
  //   setInitialState((previousState) => {
  //     return { ...previousState, previewAmountNEO: formatEther(_previewAmountNEO) };
  //   });
  // }
  // async function _previewSwapNEO2ETH(amountNEO) {
  //   // console.log("amountNEO",amountNEO);

  //   const _previewAmountETH = await contracts._token.current.read.previewSwapETH([parseEther(amountNEO).toString()]);
  //   setInitialState((previousState) => {
  //     return { ...previousState, previewAmountETH: formatEther(_previewAmountETH) };
  //   });
  // }

  // Gets NEO PREVIEW VALUE
  const _swapEthPreview = useReadContract({
    abi: abiNeoContract,
    address: addressNeoContract,
    functionName: "previewSwapTokens",
    args: [_amountEthToSwapBigInt.current],
    blockNumber: blockNumber.current,
  });

  // Gets ETH PREVIEW VALUE
  const _swapNeoPreview = useReadContract({
    abi: abiNeoContract,
    address: addressNeoContract,
    functionName: "previewSwapETH",
    args: [_amountNeoToSwapBigInt.current],
    blockNumber: blockNumber.current,
  });

  // First get Uniswap Router 02 Address
  // const _uniswapRouter02 = useReadContract({
  //   abi: abiNeoContract,
  //   address: addressNeoContract,
  //   functionName: "uniswapRouter",
  //   args: [],
  //   blockNumber: blockNumber.current,
  // });

  // _router02.current.read.WETH(); // 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
  // Second get WETH
  const _router02 = useReadContract({
    abi: abiIUniswapV2Router02, // abiIUniswapV2Router02,
    address: addressUniswapRouter02, // addressUniswapRouter02,
    functionName: "WETH", // "WETH",
    args: [],
    blockNumber: blockNumber.current,
  });

  // To get the Preview Result in one click and not in two!
  if (
    (_amountEthToSwapBigInt.current !== _amountEthToSwapBigIntPrevious.current && _swapEthPreview.isSuccess) ||
    (_amountNeoToSwapBigInt.current !== _amountNeoToSwapBigIntPrevious.current && _swapNeoPreview.isSuccess)
  ) {
    _amountEthToSwapBigInt.current = _amountEthToSwapBigIntPrevious.current;
    _amountNeoToSwapBigInt.current = _amountNeoToSwapBigIntPrevious.current;
    loading.current = true;
  }
  if (afterTransaction.current || loading.current || loadingBlockchainData) {
    if (_router02.isSuccess && _useBalanceETH.isSuccess && _useBalanceNEO.isSuccess && _swapEthPreview.isSuccess && _swapNeoPreview.isSuccess) {
      // console.log("_useBalanceETH", _useBalanceETH);
      // console.log("_useBalanceNEO", _useBalanceNEO);
      // console.log("_swapEthPreview", _swapEthPreview);
      // console.log("_swapNeoPreview", _swapNeoPreview);
      console.log("_router02", _router02);
      setLoadingBlockchainData(false);
      afterTransaction.current = false;
      loading.current = false;

      _userAmountETH.current = formatEther(_useBalanceETH.data.value.toString());
      _userAmountNEO.current = formatEther(_useBalanceNEO.data.value.toString());
      _amountEthToSwapPrev.current = formatEther(_swapEthPreview.data.toString()); // contains NEO value
      _amountNeoToSwapPrev.current = formatEther(_swapNeoPreview.data.toString()); // contains ETH value
      _weth.current = _router02.data;
      setUpdated(updated + 1);
    }
  }
  const inputEvent1 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      /* global BigInt */
      var amountWeiBigInt = BigInt(Number.parseFloat(e.target.value).toFixed(3) * 1000) * BigInt(10 ** 15);
      setInfoSwapETH(formatEther(amountWeiBigInt.toString()));
    }
  };
  const inputEvent2 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      var amountNEOBigInt = BigInt(Number.parseFloat(e.target.value).toFixed(3) * 1000) * BigInt(10 ** 15);
      setInfoSwapNEO(formatEther(amountNEOBigInt.toString()));
    }
  };
  return (
    <div>
      <hr></hr>
      <h4>Pancake Swap</h4>
      <p>Your Balance:</p>
      <p>{userAmountETH} ETH </p>
      <p>{userAmountNEO} NEO</p>
      <hr></hr>
      <p>Liquidity Pool Reserves: {lpReserveETH} ETH and {lpReserveNEO} NEO </p>
      
      {loadingBlockchainData && <div>Loading Blockchain Data ...</div>}
      {!loadingBlockchainData && (
        <div>
          <form
            onSubmit={async (event) => {
              // This function just calls the transferTokens callback with the
              // form's data.
              event.preventDefault();
              const formData = new FormData(event.target);

              const amountETH = formData.get("amountETH");
              const amountNEO = formData.get("amountNEO");

              if (btnClicked === "SwapETH2NEO" || btnClicked === "PreviewSwapETH2NEO") {
                // formData.set("amountETH", 0);
                if (Number(amountETH) > 0) {
                  var amountEthToSwapBigInt = BigInt(Number.parseFloat(amountETH).toFixed(3) * 1000) * BigInt(10 ** 15);
                  const userBalanceEthBigInt = BigInt(Number.parseFloat(userAmountETH).toFixed(3) * 1000) * BigInt(10 ** 15);
                  if (amountEthToSwapBigInt <= userBalanceEthBigInt) {
                    if (btnClicked === "SwapETH2NEO") {
                      console.log("btnClicked = SwapETH2NEO");
                      if (typeof _weth !== "undefined") {
                        const path0 = addressNeoContract; // 0xD73bAb8F06DB28c87932571f87D0D2C0FDF13D94
                        const path1 = _weth.current; // 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
                        const timestamp = blockTimestamp.current + BigInt(1000);
                        const _value = parseEther(amountETH).toString();
                        // console.log("path0",path0);
                        // console.log("path1",path1);
                        // console.log("timestamp",timestamp);
                        // console.log("_value",_value);

                        writeContract({
                          address: addressUniswapRouter02,
                          abi: abiIUniswapV2Router02,
                          functionName: "swapExactETHForTokensSupportingFeeOnTransferTokens",
                          args: [0, [path1, path0], walletAddress.address, timestamp],
                          value: _value,
                        });
                      }
                    } else {
                      _amountEthToSwapBigInt.current = amountEthToSwapBigInt;
                    }
                    afterTransaction.current = true;
                    setUpdated(updated + 1);
                  } else {
                    alert(`ETH amount ${amountETH} exceeds ETH Balance ${userBalanceEthBigInt}`);
                  }
                } else alert("ETH amount required.");
              }
              if (btnClicked === "SwapNEO2ETH" || btnClicked === "PreviewSwapNEO2ETH" || btnClicked === "Approve") {
                if (Number(amountNEO) > 0) {
                  console.log("amountNEO", amountNEO);
                  console.log("userAmountNEO", userAmountNEO);
                  var amountNeoToSwapBigInt = BigInt(Number.parseFloat(amountNEO).toFixed(3) * 1000) * BigInt(10 ** 15);
                  const userBalanceNeoBigInt = BigInt(Number.parseFloat(userAmountNEO).toFixed(3) * 1000) * BigInt(10 ** 15);
                  console.log("amountNeoToSwapBigInt", amountNeoToSwapBigInt);
                  console.log("userBalanceNeoBigInt", userBalanceNeoBigInt);

                  if (amountNeoToSwapBigInt <= userBalanceNeoBigInt) {
                    const _value = BigInt(parseEther(amountNEO).toString());
                    console.log("_value", _value);
                    if (btnClicked === "Approve") {
                      console.log("btnClicked = Approve");
                      writeContract({
                        address: addressNeoContract,
                        abi: abiNeoContract,
                        functionName: "approve",
                        args: [addressUniswapRouter02, _value],
                      });
                      setApprovalRequired(false);
                    }
                    if (btnClicked === "SwapNEO2ETH") {
                      console.log("btnClicked = SwapNEO2ETH");
                      const path0 = addressNeoContract;
                      const path1 = _weth.current;

                      const timestamp = blockTimestamp.current + BigInt(1000);
                      console.log("path0", path0);
                      console.log("path1", path1);
                      console.log("timestamp", timestamp);
                      console.log("blockTimestamp.current", blockTimestamp.current);
                     
                      writeContract({
                        address: addressUniswapRouter02,
                        abi: abiIUniswapV2Router02,
                        functionName: "swapExactTokensForETHSupportingFeeOnTransferTokens",
                        args: [
                          _value, //  Sender sends amount of Tokens
                          0,
                          [path0, path1],
                          walletAddress.address, //  Sender receives the ETH
                          timestamp,
                        ],
                      });
                    }
                    if (btnClicked === "PreviewSwapNEO2ETH") {
                      _amountNeoToSwapBigInt.current = amountNeoToSwapBigInt;
                    }
                    afterTransaction.current = true;
                    setUpdated(updated + 1);
                  } else {
                    alert(`NEO amount ${amountNEO} exceeds NEO Balance ${userBalanceNeoBigInt}`);
                  }
                } else alert("NEO amount required.");
              }

              setBtnClicked("");
              //const amountToken = 1;
            }}
          >
            <hr></hr>

            <div className="form-group col-8">
              <label>Swap your ETH into NEO</label>
              <input className="form-control" type="number" step="0.001" name="amountETH" placeholder="0.001" onChange={inputEvent1} />
              <p>You are swapping {infoSwapETH} ETH into NEO</p>
              <hr></hr>
              <button className="btn btn-light mx-2 my-2" onClick={() => setBtnClicked("SwapETH2NEO")} disabled={isPending} type="submit">
                {isPending ? "Confirming..." : "Swap ETH => NEO"}
              </button>
              <button className="btn btn-light mx-2 my-2" onClick={() => setBtnClicked("PreviewSwapETH2NEO")} type="submit">
                Preview
              </button>

              {hash && <div>Transaction Hash: {hash}</div>}
              {isConfirming && <div>Waiting for confirmation...</div>}
              {isConfirmed && <div>Transaction confirmed.</div>}
              <hr></hr>
              <p>Preview: You will receive {amountEthToSwapPrev} NEO</p>
            </div>

            <hr></hr>

            <div className="form-group col-8 my-5">
              <label>Swap your NEO into ETH</label>
              <input className="form-control" type="number" step="0.001" name="amountNEO" placeholder="0.001" onChange={inputEvent2} />
              <p>You are swapping {infoSwapNEO} NEO into ETH</p>
              <hr></hr>
              <button className="btn btn-light mx-2 my-2" onClick={() => setBtnClicked("Approve")} disabled={isPending} type="submit">
                {isPending ? "Confirming..." : "Approve"}
              </button>
              <button className="btn btn-light mx-2 my-2" onClick={() => setBtnClicked("SwapNEO2ETH")} disabled={isPending} type="submit">
                {isPending ? "Confirming..." : "Swap NEO => ETH"}
              </button>

              {hash && <div>Transaction Hash: {hash}</div>}
              {isConfirming && <div>Waiting for confirmation...</div>}
              {isConfirmed && <div>Transaction confirmed.</div>}
              <button className="btn btn-light mx-2 my-2" onClick={() => setBtnClicked("PreviewSwapNEO2ETH")} type="submit">
                Preview
              </button>
              <hr></hr>
              <p>Preview: You will receive {amountNeoToSwapPrev} ETH</p>
            </div>
          </form>

          {/* <h5>Liquidity Pool Reserves:</h5>
      <p>{lpReserveETH} ETH </p>
    <p>{lpReserveNEO} NEO </p>*/}
        
        </div>
      )}
    </div>
  );
}
