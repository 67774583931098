import { React, useState, useRef, useEffect } from "react";
import { formatEther } from 'viem';
import { useWriteContract, useWaitForTransactionReceipt, useAccount, useReadContract, useWatchContractEvent,useWatchBlockNumber} from "wagmi";

export function BurnNeo({ abiNeoContract, addressNeoContract, abiMorphContract, addressMorphContract }) {
  const [info, setInfo] = useState(0);
  
  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const blockNumber = useRef();
  const walletAddress = useAccount();

  const [userNeoBalance, setUserNeoBalance] = useState("0");
  const [updated, setUpdated] = useState(0);

  const neoBalance = useRef("0");
  const alreadyLoaded = useRef(false);
  const afterTransaction = useRef(false);
  
  useEffect(() => {

    if( neoBalance.current !== "0") {            
      setUserNeoBalance(neoBalance.current)
    } 
  }, [updated]);

  

  useWatchContractEvent({
    abi: abiNeoContract,
    address: addressNeoContract,
    eventName: 'Transfer',
    onLogs(logs) {
      console.log('Neo Transfer (Burn) logged!', logs)
      afterTransaction.current= true;
      setUpdated(updated+1);
    },
  })
  useWatchBlockNumber({
    onBlockNumber(blockNumber1) {
      // if(afterTransaction.current) {         
        blockNumber.current = blockNumber1;        
        setUpdated(updated+1);
      // }
    },
  })
  const _neoResult = useReadContract({
    abi: abiNeoContract,
    address: addressNeoContract,
    functionName: 'balanceOf',
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  })
  if(afterTransaction.current) {
    // console.log("afterTransaction");
    
      if(_neoResult.isSuccess) {        
        afterTransaction.current= false;
        neoBalance.current = formatEther(_neoResult.data.toString());

        setUpdated(updated+1);
      }
    

  }
  if(!alreadyLoaded.current) {
    if(_neoResult.isSuccess) {
      alreadyLoaded.current = true;
      // console.log("neo",_neoResult.data);
      // console.log("morph",_morphResult.data);
      // console.log("_neoResult",_neoResult);
      //   console.log("_morphResult",_morphResult);
      neoBalance.current = formatEther(_neoResult.data.toString());
      // morphBalance.current = formatEther(_morphResult.data.toString());
      setUpdated(updated+1);
    }
  }
  

  const inputEvent1 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      /* global BigInt */
      var amountNEOBigInt = BigInt(Number.parseFloat(e.target.value).toFixed(3) * 1000) * BigInt(10 ** 15);
      setInfo(formatEther(amountNEOBigInt.toString()));
    }
  };

  return (
    <div>
      <hr></hr>
      <h4>Burn NEO</h4>
      <hr></hr>
      <p>Your Balance: {userNeoBalance} NEO</p>
      <hr></hr>
      <form
        onSubmit={async (event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();
                                   
          const neoBalanceBigInt = BigInt(Number.parseFloat(userNeoBalance).toFixed(3) * 1000) * BigInt(10 ** 15);

          const formData = new FormData(event.target);
          const amountNEO = formData.get("amountNEO");
          // formData.set("amountNEO", 0);
          console.log("amountNEO",amountNEO)
          const amountNEOBigInt = BigInt(Number.parseFloat(amountNEO)) * BigInt(10 ** 18);

          if (Number(amountNEO) > 0) {
            console.log(`Burning ${amountNEO} NEO `);
            if (amountNEOBigInt <= neoBalanceBigInt) {
              //console.log("123234");
              writeContract({
                address: addressMorphContract,
                abi: abiMorphContract,
                functionName: "burnCoin1",
                args: [amountNEOBigInt.toString()],
              });
              afterTransaction.current = true;
              console.log("afterTransaction.current",afterTransaction.current);
            }
            else alert(`NEO amount ${amountNEO} exceeds your balance ${userNeoBalance}`);
          } else alert("Need the amount of NEO to burn in the input field.");
        }}
      >
        <hr></hr>
        <p className="col-8">During the burn process your MORPH get automatically staked and you receive Staking Shares as receipt.</p>
        <hr></hr>

        <div className="form-group col-8">
          <label>Burn NEO</label>
          <input className="form-control" type="number" step="1" name="amountNEO" placeholder="1"  onChange={inputEvent1}/>
          <p>
            You will burn {info} out of your total {userNeoBalance} NEO Balance.
          </p>

          <hr></hr>
          <button className="btn btn-light" disabled={isPending} type="submit">{isPending ? "Confirming..." : "Burn"}</button>
       
          {hash && <div>Transaction Hash: {hash}</div>}
          {isConfirming && <div>Waiting for confirmation...</div>}
          {isConfirmed && <div>Transaction confirmed.</div>}

        
        </div>
      </form>     
      
    </div>
  );
}
