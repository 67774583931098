import { React, useState, useRef, useEffect } from "react";
import { formatEther } from "viem";
import { useWriteContract, useWaitForTransactionReceipt, useAccount, useReadContract, useWatchContractEvent,useWatchBlockNumber} from "wagmi";

export function SwapMORPH2NEO({ abiNeoContract, addressNeoContract, abiMorphContract, addressMorphContract}) {
  const [preview, setPreview] = useState(0);

  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  const blockNumber = useRef();
  const walletAddress = useAccount();
  const [userAmountMORPH, setUserAmountMorph] = useState(0);
  const [userAmountNEO, setUserAmountNeo] = useState(0);
  const [updated, setUpdated] = useState(0);

  const morphBalance = useRef("0");
  const neoBalance = useRef("0");
  const alreadyLoaded = useRef(false);
  const afterTransaction = useRef(false);
 
  useEffect(() => {
    //console.log("walletAddress", walletAddress.address);
    //Runs on the first render
    //And any time any dependency value changes
  
    if( morphBalance.current !== "0") {     
      setUserAmountMorph(morphBalance.current)
    } 
    if( neoBalance.current !== "0") {            
      setUserAmountNeo(neoBalance.current)
    } 
  }, [updated]);

  useWatchContractEvent({
    abi: abiMorphContract,
    address: addressMorphContract,
    eventName: 'Transfer',
    onLogs(logs) {
      console.log('Morph Transfer (Burn) logged!', logs)
      afterTransaction.current= true;
      setUpdated(updated+1);
    },
  })
  

  
  

  useWatchBlockNumber({
    onBlockNumber(blockNumber1) {
      // if(afterTransaction.current) {         
        blockNumber.current = blockNumber1;        
        setUpdated(updated+1);
      // }
    },
  })

  const _morphResult = useReadContract({
    abi: abiMorphContract,
    address: addressMorphContract,
    functionName: 'balanceOf',
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  })
  const _neoResult = useReadContract({
    abi: abiNeoContract,
    address: addressNeoContract,
    functionName: 'balanceOf',
    args: [walletAddress.address],
    blockNumber: blockNumber.current,
  })
  
  if(afterTransaction.current) {
    // console.log("afterTransaction");
    
    if(isConfirmed){
      if(_morphResult.isSuccess && _neoResult.isSuccess) {        
        afterTransaction.current= false;
        neoBalance.current = formatEther(_neoResult.data.toString());
        morphBalance.current = formatEther(_morphResult.data.toString());
        // console.log("afterTransaction1");
        // console.log("_neoResult",_neoResult);
        // console.log("_morphResult",_morphResult);        
        // console.log("neoBalance.current", neoBalance.current)
        // console.log("morphBalance.current", morphBalance.current)
        setUpdated(updated+1);
      }
    }

  }
  if(!alreadyLoaded.current) {
    
    if(_morphResult.isSuccess && _neoResult.isSuccess) {
      alreadyLoaded.current = true;
      // console.log("neo",_neoResult.data);
      // console.log("morph",_morphResult.data);
      // console.log("_neoResult",_neoResult);
      //   console.log("_morphResult",_morphResult);
      neoBalance.current = formatEther(_neoResult.data.toString());
      morphBalance.current = formatEther(_morphResult.data.toString());
      setUpdated(updated+1);
    }
  }
  

  // async function submit(e: React.FormEvent) {
  //   e.preventDefault()
  //   const formData = new FormData(e.target) // as HTMLFormElement)
  //   const tokenId = formData.get('tokenId') // as string
  // }
  const inputEvent1 = (e) => {
    if (e.target.value !== "undefined" && e.target.value !== "NaN" && e.target.value !== "") {
      /* global BigInt */
      var amountMORPHBigInt = BigInt(Number(parseFloat(e.target.value).toFixed(6) * 1000000)) * BigInt(10 ** 12);
      let amountNEOBigInt = amountMORPHBigInt - amountMORPHBigInt / BigInt(100);

      setPreview(formatEther(amountNEOBigInt.toString()));
    }
  };
  return (
    <div>
      <hr></hr>
      <h4>Swap MORPH to NEO</h4>
      <p>Your Balance:</p>
      <p>{userAmountNEO} NEO</p>
      <p>{userAmountMORPH} MORPH</p>
      <hr></hr>
      <form
        onSubmit={async (event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();
          
          const userAmountMORPHBigInt = BigInt(Number(parseFloat(userAmountMORPH).toFixed(6) * 1000000)) * BigInt(10 ** 12);

          const formData = new FormData(event.target);

          const amountMORPH = formData.get("amountMORPH");
          formData.set("amountMORPH", 0);
          const amountMORPHBigInt = BigInt(Number(parseFloat(amountMORPH).toFixed(6) * 1000000)) * BigInt(10 ** 12);

          if (Number(amountMORPH) > 0) {
            if (amountMORPHBigInt <= userAmountMORPHBigInt) {

              writeContract({
                address: addressNeoContract,
                abi: abiNeoContract,
                functionName: "swapCoin2toCoin1Public",
                args: [amountMORPHBigInt.toString()],
              });
              afterTransaction.current = true;
      
            } else {
              alert(`MORPH amount ${amountMORPH} exceeds your balance ${userAmountMORPH}`);
            }
          } else alert("Need the amount of MORPH to swap in the input field.");
        }}
      >
        <hr></hr>
        <p>Swap your MORPH into NEO.</p>
        {/* <div className="form-group col-8" onChange={inputEvent1}> */}
        <div className="form-group col-8">
          <label>Swap MORPH</label>
          <input className="form-control" type="number" step="0.001" name="amountMORPH" placeholder="0.001" onChange={inputEvent1}/>
          <p>You receive {preview} NEO.</p>
          <hr></hr>
          <button className="btn btn-light" disabled={isPending} type="submit">{isPending ? "Confirming..." : "Swap"}</button>
       
          {hash && <div>Transaction Hash: {hash}</div>}
          {isConfirming && <div>Waiting for confirmation...</div>}
          {isConfirmed && <div>Transaction confirmed.</div>}
        </div>
      </form>
      
    </div>
  );
}
