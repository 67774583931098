import { React } from "react";

export function Overview({ abiStakingContract, addressStakingContract, abiMorphContract, addressMorphContract }) {
  return (
    <div>
      <hr></hr>
      <h4>Overview </h4>
      {/* <h4>Overview {tokenSymbol}</h4>
      <h5>Your Balances:</h5>
      <p>{userBalanceEth} ETH</p>
      <p>{userBalanceNEO} NEO</p>
      <p>{userBalanceUsdt} USDT</p>
      <p>{userBalanceMorph} MORPH</p>
      <p>{stakedShares} Shares staked</p>
      <p></p>
      <hr></hr>
      <h5>Pancake Swap Liquidity</h5>
      <p>{lpReserveETH} ETH </p>
      <p>{lpReserveNEO} NEO </p>
      <hr></hr>
                  
      <p>Vesting Pool Morph Balance: {vestingPoolMorphBalance}</p>
      <p>Staking Pool Morph Balance: {stakingPoolMorphBalance}</p>
      <p>Trigger NEO Balance: {triggerCoin1Balance}</p>
      <p>Marketing NEO Balance: {marketingCoin1Balance}</p>
      <hr></hr>
      <p>burnFee: {burnFee} %</p>
      <p>minBalanceSwapToken: {minBalanceSwapToken}</p>
      <p>Threshold for triggering next NEO Burn {burnCountdown}</p> */}
    </div>
  );
}
